<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-primary my-2 f-14 formBadge width"> Détails sur la trésorerie</span>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label>Nom </label>
                <input class="form-control p-2" type="text" [value]="treasury?.nom" readonly/>
              </div>
              <div class="col-md-4">
                <label>Seuil minimum </label>
                <input class="form-control p-2" type="text" [value]="treasury?.seuilMin|number" readonly/>
              </div>
              <div class="col-md-4">
                <label>Seuil maximum </label>
                <input class="form-control p-2" type="text" [value]="treasury?.seuilMax|number" readonly/>
              </div>
              <div class="col-md-6">
                <label>Ligne budgétaire <span class="asterix">*</span></label>
                  <ng-select id="ligne" formControlName="ligne">
                      <ng-option *ngFor="let item of lignes" [value]="item.uuid">{{item?.libelle}}</ng-option>
                  </ng-select>
                  <div class="invalid-feedback" *ngIf="submit && f.ligne.errors">
                      <div *ngIf="f.ligne.errors.required">{{required.novide}}</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-primary my-2 f-14 formBadge width"> DÉTAILS DE L'OPÉRATION</span>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="date">Date de l'opération <span class="asterix">*</span></label>
                <input class="form-control" type="date"formControlName="date"  id="date"/>
                <div class="invalid-feedback" *ngIf="submit && f.date.errors">
                  <div *ngIf="f.date.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="motif">Motif <span class="asterix">*</span></label>
                <input class="form-control" type="text" formControlName="motif"  id="motif" />
                <div class="invalid-feedback" *ngIf="submit && f.motif.errors">
                  <div *ngIf="f.motif.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="priorite">Priorité<span class="asterix">*</span></label>
                <select class="form-control" id="priorite" formControlName="priorite" [ngClass]="{
                    'is-invalid': submit && f.priorite.errors,
                    'is-valid': submit && f.priorite.valid
                  }">
                  <option *ngFor="let item of prioriteRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.priorite.errors">
                  <div *ngIf="f.priorite.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="description">Description </label>
                <textarea class="form-control b-2" id="description" formControlName="description"
                  rows="2"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-primary my-2 f-14 formBadge width"> Détails sur le lot</span>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="row">
              <!-- <div class="col-md-6">
                <label for="houseCo">Lot</label>
                <select class="form-control" id="houseCo" formControlName="houseCo" [ngClass]="{
                    'is-invalid': submit && f.houseCo.errors,
                    'is-valid': submit && f.houseCo.valid
                  }" (change)="onChangeLot($event.target.value)">
                  <option value="null" selected> Sélectionnez un lot</option>
                  <option *ngFor="let item of lots" [value]="item.uuid">{{item.nom}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.houseCo.errors">
                  <div *ngIf="f.houseCo.errors.required">{{required.novide}}</div>
                </div>
              </div> -->
              <div class="col-md-6" *ngIf="lots && lots.length > 0">
                <label for="lot">Lot</label>
                <ng-select [items]="lots" bindLabel="nom" bindValue="uuid" 
                  [groupBy]="'house'" [selectableGroup]="true"
                  (change)="onChangeLot($event)">
                </ng-select>
              </div>
              <div class="col-md-6">
                <label for="infrastructure">Infrastructure</label>
                <select class="form-control" id="infrastructure" formControlName="infrastructure" [ngClass]="{
                    'is-invalid': submit && f.infrastructure.errors,
                    'is-valid': submit && f.infrastructure.valid
                  }" (change)="onChangeInfrastructure($event.target.value)">
                  <option value="null" selected> Sélectionnez une infrastructure</option>
                  <option *ngFor="let item of infrastructures" [value]="item.uuid">{{item.nom}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.infrastructure.errors">
                  <div *ngIf="f.infrastructure.errors.required">{{required.novide}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-primary my-2 f-14 formBadge width">Ajouter des options </span>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div formArrayName="options">
              <div class="form-group" *ngFor="let opt of option.controls; let i=index" [formGroupName]="i">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label>
                      Désignation <span class="asterix">*</span> 
                    </label>
                    <ng-select  formControlName="libelle"
                      [addTag]="addProduct" [items]="products" [selectOnTab]="true" bindValue="libelle"
                      bindLabel="libelle" labelForId="produit" (change)="onSelectChange(opt,$event)">
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>Ajouter</b>: {{search}}
                      </ng-template>
                    </ng-select>
                  </div>

                  <div class="col-md-6">
                    <label>Compte comptable </label>
                  <ng-select id="account" formControlName="account" [readonly]="editAccount">
                      <ng-option *ngFor="let item of accounts" [value]="item.uuid">{{item?.libelle}}</ng-option>
                  </ng-select>
                  <!-- <div class="invalid-feedback" *ngIf="submit && f.account.errors">
                      <div *ngIf="f.account.errors.required">{{required.novide}}</div>
                  </div> -->
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4">
                    <label>Prix unitaire <span class="asterix">*</span></label>
                    <input (change)="onChangeTotal()" type="number" formControlName="prix" min="0"
                      class="form-control" id="prix{{i}}"
                      [ngClass]="{
                        'is-invalid': submit && opt.get('prix').errors,
                        'is-valid': submit && opt.get('prix').valid
                      }" placeholder="Prix unitaire">
                    <div class="invalid-feedback" *ngIf="submit && opt.get('prix').errors">
                      <div *ngIf="opt.get('prix').errors.required">{{required.novide}}</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="submit && opt.get('prix').errors">
                      <div *ngIf="opt.get('prix').errors.required">{{required.novide}}</div>
                      <div *ngIf="opt.get('prix').errors.pattern">{{required.nolettre}}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label>Qté <span class="asterix">*</span></label>
                    <input (change)="onChangeTotal()" type="number" formControlName="qte" min="1"
                      class="form-control" id="qte{{i}}"
                      [ngClass]="{
                        'is-invalid': submit && opt.get('qte').errors,
                        'is-valid': submit && opt.get('qte').valid
                      }" placeholder="Quantité">
                    <div class="invalid-feedback" *ngIf="submit && opt.get('qte').errors">
                      <div *ngIf="opt.get('qte').errors.required">{{required.novide}}</div>
                      <div *ngIf="opt.get('qte').errors.pattern">{{required.nolettre}}</div>
                      <div *ngIf="opt.get('qte').errors.pattern">{{required.positive}}</div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <label>TVA</label>
                    <input (change)="onChangeTotal()" type="number" formControlName="tva" min="0"
                          class="form-control p-2" id="tva{{i}}"
                          [ngClass]="{
                        'is-invalid': submit && opt.get('tva').errors,
                        'is-valid': submit && opt.get('tva').valid
                      }" placeholder="TVA">
                    <div class="invalid-feedback" *ngIf="submit && opt.get('tva').errors">
                      <div *ngIf="opt.get('tva').errors.pattern">{{required.nolettre}}</div>
                      <div *ngIf="opt.get('tva').errors.pattern">{{required.positive}}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label>Remise</label>
                    <input (change)="onChangeTotal()" type="number" formControlName="remise" min="0"
                      class="form-control" id="remise{{i}}">
                  </div>
                  <div class="col-md-2 mt-2">
                    <label>Total</label>
                    <input type="number" formControlName="total" class="form-control p-2" id="total{{i}}" min="0"
                        step="1000" placeholder="TOTAL" readonly>
                    <div class="invalid-feedback" *ngIf="submit && opt.get('total').errors">
                      <div *ngIf="opt.get('total').errors.pattern">{{required.nolettre}}</div>
                    </div>
                  </div>
                  <div class="col-md-1 mt-2">
                    <button (click)="onDelete(opt)" type="button" class="btn btn-icon btn-danger">
                      <i class="feather icon-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 d-flex flex-row-reverse">
              <button (click)="addOption()" type="button" class="btn btn-primary">
                Ajouter <i class="feather icon-plus"></i>
              </button>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <table class="table table-responsive invoice-table invoice-total">
                  <tbody>
                  <tr>
                    <th>TOTAL HT :</th>
                    <td>{{ totalHT | number }}</td>
                  </tr>
                  <tr>
                    <th>TOTAL REMISE :</th>
                    <td>{{ totalRemise | number }}</td>
                  </tr>
                  <tr>
                    <th>TOTAL TVA :</th>
                    <td>{{ totalTva | number }}</td>
                  </tr>
                  <tr class="text-info">
                    <td>
                      <hr/>
                      <h2 class="text-primary m-r-10">TOTAL :</h2>
                    </td>
                    <td>
                      <hr/>
                      <h2 class="text-success">{{ totalTTC | number }}</h2>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
