import {Rent} from '@model/rent';
import {Renew} from '@model/renew';
import {Tenant} from '@model/tenant';
import {Invoice} from '@model/invoice';
import {Contract} from '@model/contract';
import {Penality} from '@model/penality';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { Globals } from '@theme/utils/globals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HC_drilldown from 'highcharts/modules/drilldown';
import {FilterService} from '@service/filter/filter.service';
import { TenantService } from '@service/tenant/tenant.service';
import { ContractService } from '@service/contract/contract.service';
import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { dashboardTenantService } from '@service/dashboard-tenant/dashboard-tenant.service';
import { ContractShowComponent } from '@agence/locataire/contract/contract-show/contract-show.component';
HC_drilldown(Highcharts);

@Component({
  selector: 'app-dash-locataire',
  templateUrl: './dash-locataire.component.html',
  styleUrls: ['./dash-locataire.component.scss']
})
export class DashLocataireComponent implements OnInit {

  pieFactureA = 0
  pieFactureI = 0
  pieFactureE = 0
  pieFactureS = 0

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any

  dtOptions: any = {};
  etat: boolean = false
  isPrint: boolean = true;
  type: string = 'TOUT';
  widget : any
  filter: any;
  listing : any;
  userSession = Globals.user;
  totalTenantI = 0
  typeRow = [
    {label: 'TOUT', value: 'TOUT'},
    {label: 'LOYER', value: 'LOYER'},
    {label: 'FACTURE D\'ENTREE', value: 'ENTREE'},
    {label: 'PENALITE', value: 'PENALITE'},
    {label: 'AUTRES FACTURES', value: 'AUTRE'},
    {label: 'RENOUVELLEMENT', value: 'RENOUVELLEMENT'},
    {label: 'RESILIATION', value: 'RESILIATION'},

  ];
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    ordre: "ASC",
    type: "TOUT",
    uuid: null
  }
  renews: Renew[];
  payments = [];
  rents: Rent[];
  penalities: Penality[];
  invoices: Invoice[];
  autres: Invoice[];
  bande: any
  circulaire: any
  public data: any;
  public intervalSub: any;
  public intervalMain: any;
  public lastDate: number;
  global = {country: Globals.country, device: Globals.device}
  tenantI: Tenant[];
  contractA: Contract[];
  contractR: Contract[];
  contractRe: Contract[];
  mois = []
  rentI = []
  rentT = []
  rentP = []
  widgetTotalTitle: string = "Facture(s)";
  widgetCoursTitle: string = "En cours";
  widgetAttenteTitle: string = "Les factures à recouvrer";
  widgetSoldeTitle: string = "Soldée(s)";
  widgetImpayeTitle: string = "Impayée(s)";

  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;


  nameTitle: string = "Propriétaires"
  name: boolean = true;
  nameType = 'ENTITY';
  nameClass= 'Owner';
  nameNamespace= 'Client';
  nameGroups= 'owner';

  bienTitle: string = "Nom du bien"
  bien: boolean = true
  bienType = 'ENTITY';
  bienClass= 'House';
  bienNamespace= 'Client';
  bienGroups= 'house';

  autreTitle = "Locataires";
  autre: boolean = true;
  autreType = 'ENTITY';
  autreClass= 'Tenant';
  autreNamespace= 'Client';
  autreGroups= 'tenant';

  constructor(
    public router: Router,
    private modalService: NgbModal,
    private filterService: FilterService,
    private tenantService: TenantService,
    private contractService: ContractService,
    private dashboardTenantService: dashboardTenantService
  ) {
    this.onFilter(this.event)
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.getPie();
    this.getGraph();
    this.filterService.dashboard(this.event, 'tenant', null).subscribe(
      res => {
        this.getGraph();
        this.getPie();
        this.onSet(res);
    }, err => { })
  }

  onFilter($event) {
    this.type = $event.type
    this.filterService.dashboard($event, 'tenant', null).subscribe(
      res => {
        this.etat = res ? true : false
        this.widget = res?.widget
        this.tenantI = res?.tenantI;
        this.contractA = res?.contractA;
        this.contractR = res?.contractR;
        this.contractRe = res?.contractRe;
        // @ts-ignore
        this.tenantI.forEach(item => { return this.totalTenantI = this.totalTenantI + item?.impaye })
        if(this.type === 'TOUT'){
          this.onSet(res);
        } else if(this.type === 'RENEW'){
        } else if(this.type === 'ENTREE'){
        } else if(this.type === 'AUTRE'){
        } else if(this.type === 'LOYER'){
          this.onSet(res);
        } else if(this.type === 'PENALITE'){
        } else if(this.type === 'RESILIATION'){
        }
    }, err => { })
  }

  onPrinter($event){
    console.log($event);
    this.dashboardTenantService.getPrinter($event ,this.userSession?.agencyKey, this.userSession?.uuid);
    // if (!this.widget || !this.listing) {
    //   this.filter = $event
    //   // this.dashboardService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
    //   // this.dashboardService.getPrinter('SHOW', this.userSession.agencyKey, this.userSession?.uuid, row?.uuid);
      // this.dashboardTenantService.getPrinter('LOCATAIRE',this.userSession?.agencyKey, this.userSession?.uuid, this.filter)
      // return;
    // }
  }

  onChangeLoad($event) {
    this.type = $event
    if($event === 'TOUT'){
      this.widgetTotalTitle = "Facture(s)";
      this.widgetCoursTitle = "En cours(s)";
      this.widgetAttenteTitle = "Attente(s)";
      this.widgetSoldeTitle = "Soldé(s)";
      this.widgetImpayeTitle = "Impayé(s)";
    } else if($event === 'LOYER'){
      this.widgetTotalTitle = "Loyer(s) total";
      this.widgetCoursTitle = "Loyer(s) en cours(s)";
      this.widgetAttenteTitle = "Loyer(s) en attente(s)";
      this.widgetSoldeTitle = "Loyer(s) soldé(s)";
      this.widgetImpayeTitle = "Loyer(s) impayé(s)";
    } else if($event === 'ENTREE'){
      this.widgetTotalTitle = "Facture d'entrée(s) total";
      this.widgetCoursTitle = "Facture d'entrée(s) en cours(s)";
      this.widgetAttenteTitle = "Facture d'entrée(s) en attente(s)";
      this.widgetSoldeTitle = "Facture d'entrée(s) soldé(s)";
      this.widgetImpayeTitle = "Facture d'entrée(s) impayée(s)";
    } else if($event === 'PENALITE'){
      this.widgetTotalTitle = "Pénalité(s) total";
      this.widgetCoursTitle = "Pénalité(s) en cours(s)";
      this.widgetAttenteTitle = "Pénalité(s) en attente(s)";
      this.widgetSoldeTitle = "Pénalité(s) soldé(s)";
      this.widgetImpayeTitle = "Pénalité(s) impayée(s)";
    } else if($event === 'AUTRE'){
      this.widgetTotalTitle = "Autres facture(s) total";
      this.widgetCoursTitle = "Autres facture(s) en cours(s)";
      this.widgetAttenteTitle = "Autres facture(s) en attente(s)";
      this.widgetSoldeTitle = "Autres facture(s) soldé(s)";
      this.widgetImpayeTitle = "Autres facture(s) impayée(s)";
    } else if($event === 'RENEW'){
      this.widgetTotalTitle = "Renouvellement(s) total";
      this.widgetCoursTitle = "Renouvellement(s) en cours(s)";
      this.widgetAttenteTitle = "Renouvellement(s) en attente(s)";
      this.widgetSoldeTitle = "Renouvellement(s) soldé(s)";
      this.widgetImpayeTitle = "Renouvellement(s) impayé(s)";
    } else if($event === 'RESILIATION'){
      this.widgetTotalTitle = "Résiliation(s) total";
      this.widgetCoursTitle = "Résiliation(s) en cours(s)";
      this.widgetAttenteTitle = "Résiliation(s) en attente(s)";
      this.widgetSoldeTitle = "Résiliation(s) soldé(s)";
      this.widgetImpayeTitle = "Résiliation(s) impayée(s)";
    }
    console.log('je suis la oh')

  }
  showContract(row): void {
    this.contractService.setContract(row);
    this.modal(ContractShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  showTenant(row) {
    this.tenantService.setTenant(row);
    this.router.navigate(['/admin/locataire/show/' + row.uuid]);
  }
  onSet(res){
    this.getGraph();
    this.rentT = []
    this.rentP = []
    this.rentI = []
    this.mois = []
    if (res) {
      res?.facture?.forEach(el => {
        this.rentT.push(el?.total)
        this.rentP.push(el?.paye)
        this.rentI.push(el?.impaye)
        this.mois.push(el?.date)
      });
      this.pieFactureA = res?.pieFacture.nbrA
      this.pieFactureI = res?.pieFacture.nbrI
      this.pieFactureE = res?.pieFacture.nbrE
      this.pieFactureS = res?.pieFacture.nbrS
    }
  }
  getGraph () {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['#0e9e4a', '#4680ff', '#ff5252'],
      title: {
        text: "Visualisation en bande de la structure des montants."
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
      {
        color: '#0e9e4a',
        name: 'Payés',
        data: this.rentP

      }, {
        color: '#4680ff',
        name: 'Total',
        data: this.rentT

      },{
        color: '#ff5252',
        name: 'Impayés',
        data: this.rentI

      }
    ]
    };
  }
  getPie(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChart.nativeElement as HTMLCanvasElement).children));
      this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartData = {
        labels: ['En attentes', 'Impayés', 'En cours', 'Soldés'],
        datasets: [{
          data: [this.pieFactureA, this.pieFactureI, this.pieFactureE, this.pieFactureS],
          backgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a'],
          hoverBackgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a']
        }]
      };
    });
  }
  resetData() { this.data = this.data.slice(this.data.length - 10, this.data.length); }

  getNewSeries(baseval, yrange) {
    const newDate = baseval + 86400000;
    this.lastDate = newDate;
    this.data.push({
      x: newDate,
      y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    });
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
}
