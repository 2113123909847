import { MenuAddComponent } from '@utilisateur/menus/menu-add/menu-add.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@service/menu/menu.service';
import { Menu } from '@model/menu';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit, Input } from '@angular/core';
import { MenuShowComponent } from '../menu-show/menu-show.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {
  @Input() menus: Menu[]
  type: string = "MENU"
  etat: boolean = false
  dtOptions: any = {};
  global = {country: Globals.country, device: Globals.device}

  constructor(
    private modalService: NgbModal,
    private permissionService: MenuService,
    private permissionsService: NgxPermissionsService
  ) {
    const menu = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(menu);
  }

  ngOnInit(): void {
    if(this.menus){ this.etat = true }
    this.dtOptions = Globals.dataTable;
  }

  showMenu(row) {
    this.permissionService.setMenu(row)
    this.modal(MenuShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  editMenu(row) {
    this.modalService.dismissAll()
    this.permissionService.setMenu(row)
    this.permissionService.edit = true
    this.modal(MenuAddComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        Swal.fire('', 'Enrégistrement supprimé avec succès !', 'success');
      }
    });
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
}
