<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Locataire : </span>
        <span class="title-result-show">{{ notice?.contract?.tenant?.searchableTitle }}</span>
      </p>
      <p>
        <span class="title-show">Locative : </span>
        <span class="title-result-show">Porte N° {{ notice?.contract?.rental?.porte }}</span>
      </p>
      <p>
        <span class="title-show">contrat : </span>
        <span class="title-result-show">contrat N° {{ notice?.contract?.code }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <!-- COL MILIEU -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Loyer : </span>
        <span class="title-result-show">{{ notice?.rent?.mois }}</span>
      </p>
      <p>
        <span class="title-show">Montant : </span>
        <span class="title-result-show text-warning">{{ notice?.montant|number }} {{ global.device }}</span>
      </p>
      <p>
        <span class="title-show">Payé : </span>
        <span class="title-result-show text-warning">{{ notice?.paye|number }} {{ global.device }}</span>
      </p>
    </div>
    <!-- COL MILIEU -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Impayé : </span>
        <span class="title-result-show text-warning">{{ notice?.impaye|number }} {{ global.device }}</span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{ notice?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ notice?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ notice?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ notice?.update }}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <span class="badge badge-secondary my-2 f-14  width"> Détails sur le loyer courant </span>
  </div>
  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Période</th>
          <th>Montant</th>
          <th>Payé</th>
          <th>Impayé</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><p class="m-0 d-inline-block align-middle font-16">{{notice?.rent.mois}}</p></td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(notice?.rent?.invoice?.montant)|number}} {{global.device}}</span></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{notice?.rent?.invoice?.paye|number}} {{global.device}}</span></p>
          </td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{notice?.rent?.invoice?.impaye|number}} {{global.device}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="notice?.options.length > 0" class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Période</th>
          <th>Montant</th>
          <th>Payé</th>
          <th>Impayé</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of notice?.options" >
          <td><p class="m-0 d-inline-block align-middle font-16">{{item?.mois}}</p></td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(item?.montant)|number}} {{global.device}}</span></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.paye|number}} {{global.device}}</span></p>
          </td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.impaye|number}} {{global.device}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
  <table class="width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
        <td class="font-weight-bold font-size-default text-right">{{ notice?.montant | number }} {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button *ngxPermissionsOnly="'NOTICE:PRINTER:SHOW'" (click)="printerNotice(notice)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
