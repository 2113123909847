<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{supply?.code }}</span>
      </p>
      <p>
        <span class="title-show">Trésorerie : </span>
        <span class="title-result-show">{{ supply?.treasury?.searchableTitle }}</span>
      </p>
      <p>
        <span class="title-show">Libellé : </span>
        <span class="title-result-show">{{ supply?.libelle }}</span>
      </p>
      <p>
        <span class="title-show">Motif : </span>
        <span class="title-result-show">{{ supply?.motif }}</span>
      </p>
      <p *ngIf="supply?.mode !== 'ESPECE'">
        <span class="title-show">Mode : </span>
        <span class="title-result-show">{{ supply?.mode }}</span>
      </p>
      <p *ngIf="supply?.mode !== 'ESPECE'">
        <span class="title-show">date : </span>
        <span class="title-result-show">{{ supply?.date| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p *ngIf="supply?.mode == 'ESPECE'">
        <span class="title-show">Mode : </span>
        <span class="title-result-show">{{ supply?.mode }}</span>
      </p>
      <p *ngIf="supply?.mode == 'ESPECE'">
        <span class="title-show">date : </span>
        <span class="title-result-show">{{ supply?.date| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
      </p>
      <p *ngIf="supply?.mode !== 'ESPECE'">
        <span class="title-show">Source : </span>
        <span class="title-result-show">{{ supply.source }}
        </span>
      </p>
      <p *ngIf="supply?.mode !== 'ESPECE'">
        <span class="title-show">Numéro : </span>
        <span class="title-result-show">{{ supply.numero }}
        </span>
      </p>
      <p>
        <span class="title-show">Montant : </span>
        <span class="title-result-show text-warning">{{ supply.montant | number}} {{ global.device }}
        </span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{supply?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ supply?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ supply?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{supply?.update }}</span>
      </p>
    </div>
  </div>
      <table class="table">
        <tfoot>
          <tr class="border border-success">
            <td colspan="4" class="font-weight-bold font-size-default">TOTAL</td>
            <td class="text-right font-weight-bold font-size-default">{{ supply?.montant | number }} {{ global.device }}</td>
          </tr>
        </tfoot>
      </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button (click)="editSupply(supply)" class="btn btn-primary">
      Modifier <i class="feather icon-edit-2"></i>
  </button>
  <button (click)="printerSupply(supply)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
