import { Globals } from '@theme/utils/globals';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { FilterService } from '@service/filter/filter.service';

@Component({
  selector: 'app-etat-finance',
  templateUrl: './etat-finance.component.html',
  styleUrls: ['./etat-finance.component.scss']
})
export class EtatFinanceComponent implements OnInit {
  form: FormGroup
  advance: boolean = true
  cookie: string = ''
  filter: any;
  userSession = Globals.user;
  dtOptions: any = {};
  ownerSelected: any;
  tenantSelected: any;
  customerSelected: any;
  houseSelected: any;
  promotionSelected: any;
  subdivisionSelected: any;
  tresorerieSelected: any;
  userSelected: any;
  global = {country: Globals.country, device: Globals.device}
  countRow = [
    { label: "Tout", value: 0 },
    { label: "1", value: 1 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 }
  ]
  locations = []
  sum: any

  constructor(
    private formBuild: FormBuilder,
    private filterService: FilterService,

  ) {
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.form = this.formBuild.group({
      type: ["ETAT"],
      owner: [null],
      tenant: [null],
      customer: [null],
      user: [null],
      house: [null],
      promotion: [null],
      subdivision: [null],
      tresorerie: [null],
      dateD: [null],
      dateF: [null],
      min: [null],
      max: [null],
      ordre: [null],
      count: [10]
    })
  }

  setValue(uuid, type){
    if(type === 'owner'){
      this.f.owner.setValue(uuid ? uuid : null);
    } else if(type === 'tenant'){
      this.f.tenant.setValue(uuid ? uuid : null);
    } else if(type === 'customer'){
      this.f.customer.setValue(uuid ? uuid : null);
    }  else if(type === 'user'){
      this.f.user.setValue(uuid ? uuid : null);
    }else if(type === 'house'){
      this.f.house.setValue(uuid ? uuid : null);
    } else if(type === 'promotion'){
      this.f.promotion.setValue(uuid ? uuid : null);
    } else if(type === 'subdivision'){
      this.f.subdivision.setValue(uuid ? uuid : null);
    } 
  }

  public onFilter() {
    const event = this.form.getRawValue();
    this.filterService.search(event, 'etat-fiancier', null).subscribe(
      res => {
        this.locations = res.locations;
        return this.sum = res.sum;
    }, err => { })

  }

  public onPrinter() {
  }
  get f() { return this.form.controls }
}
