<div class="col-md-12">
  <span class="badge badge-success my-2 f-14 formBadge width"> Liste des services </span>
</div>
<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th *ngIf="user">Responsable</th>
            <th>Nom</th>
            <th>Direction</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of services">
            <td *ngIf="user" class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-body text-warning">{{ item?.responsable?.libelle }}</span><br />
                <span class="text-body">({{ item?.responsable?.telephone }} / {{ item?.responsable?.email }})</span>
              </p>
            </td>
            <td><p class="align-middle">{{ item?.nom }}</p></td>
            <td><p class="align-middle">{{ item?.direction }}</p></td>
            <td class="table-action">
              <div class="overlay-edit">
                <button *ngxPermissionsOnly="'SERVICE:SHOW'" (click)="showService(item)" type="button" class="btn btn-icon btn-secondary"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button *ngxPermissionsOnly="'SERVICE:EDIT'" (click)="editService(item)" type="button" class="btn btn-icon btn-info ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'SERVICE:DELETE'" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>
