<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <!-- Détails sur le bien CONCERNE -->
      <div class="row">
        <span class="badge badge-secondary my-2 f-14 width"> Informations </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-12" *ngIf="type === 'HOUSE'">
            <app-entity-finder [class]="'House'" [groups]="['house']" [required]="true" [label]="'Bien'"
              (uuid)="setHouseUuid($event)" [selected]="entitySelected"
              [placeholder]="'Sélectionnez un Bien'">
            </app-entity-finder>
          </div>
          <div class="col-md-12" *ngIf="type === 'PROMOTION'">
            <app-entity-finder [class]="'Promotion'" [groups]="['promotion']" [required]="true" [label]="'Promotion'"
              (uuid)="setPromotionUuid($event)" [selected]="entitySelected"
              [placeholder]="'Sélectionnez une promotion'">
            </app-entity-finder>
          </div>
          <div class="col-md-12" *ngIf="type === 'LOTISSEMENT'">
            <app-entity-finder [class]="'Subdivision'" [groups]="['subdivision']" [required]="true" [label]="'lotissement'"
              (uuid)="setSubdivisionUuid($event)" [selected]="entitySelected"
              [placeholder]="'Sélectionnez un lotissement'">
            </app-entity-finder>
          </div>
        </div>
      </div>

      <div class="row">
        <span class="badge badge-secondary my-2 f-14 width"> Attribution des validateurs </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md mt-4">
            <dual-list (destinationChange)="setData()" [sort]="keepSorted" [source]="source" [key]="key" [display]="display" [filter]="true"
              [(destination)]="confirmed" height="265px" [format]="format" [disabled]="disabled">
            </dual-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
