<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
        <tr>
          <th>Locative</th>
          <th *ngIf="locataire">Locataire</th>
          <th>État</th>
          <th>Période</th>
          <th>Crée le</th>
          <th>Montant</th>
          <th>Payé</th>
          <th>Impayé </th>
          <th>Trop Percu </th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of invoices">
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.contract?.rental?.libelle}}</span> <br />
                Superficie : {{item?.contract?.rental?.superficie}} m² - {{item?.contract?.rental?.piece}} pièce(s): <br />
                Propriétaire : {{item?.contract?.rental?.house?.owner?.searchableTitle}}
              </p>
            </td>
            <td *ngIf="locataire">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.contract?.tenant?.searchableTitle}}</span> <br />
                Type : {{item?.contract?.tenant?.type}}<br />
                Téléphone : {{item?.contract?.tenant?.telephone}}
              </p>
            </td>
            <td>
              <span class="badge" [ngClass]="{
              'badge-danger' : item?.etat === 'IMPAYÉ',
              'badge-primary' : item?.etat === 'ATTENTE',
              'badge-warning' : item?.etat === 'EN COURS',
              'badge-success' : item?.etat === 'SOLDE'
              }">{{payment(item?.etat)}}</span>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">{{ item?.libelle }}</p>
              <br *ngIf="item?.contract?.etat !== 'ACTIF'"/>
              <span *ngIf="item?.contract?.etat !== 'ACTIF'" class="badge" [ngClass]="{
                'badge-light-danger' : item?.contract?.etat === 'RESILIE',
                'badge-light-warning' : item?.contract?.etat === 'INACTIF',
                'badge-light-primary' : item?.contract?.etat === 'ACTIF'
                }"><small>{{item?.statut}}</small></span>
            </td>
            <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-primary font-weight-blod">{{item?.montant|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-success font-weight-blod">{{item?.paye|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-danger font-weight-blod">{{item?.impaye|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-danger font-weight-blod">{{item?.solde|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showEntranceInvoice(item)" type="button" class="btn btn-icon btn-secondary"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button *ngxPermissionsOnly="'INVOICE:PRINTER:SHOW'" (click)="printerInvoice(item)" type="button" class="btn btn-icon btn-warning ml-1"
                  ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="locataire ? '5' : '4'" class="font-weight-bold font-size-40 text-right">TOTAL</td>
            <td class="font-weight-bold font-size-40 text-primary"> {{total | number}} {{global.device}}</td>
            <td class="font-weight-bold font-size-40 text-success"> {{paye | number}} {{global.device}}</td>
            <td class="font-weight-bold font-size-40 text-danger"> {{impaye | number}} {{global.device}}</td>
            <td *ngIf="action"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </app-card>
</div>
