import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';


import { SharedModule } from '@theme/shared/shared.module';
import { FinanceRoutingModule } from './finance-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EtatFinanceComponent } from './etat-finance/etat-finance.component';
import { NgbAccordionModule, NgbCollapseModule, NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';


@NgModule({
  declarations: [EtatFinanceComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    NgbTooltipModule,
    DataTablesModule,
    NgbCollapseModule,
    NgbAccordionModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FinanceRoutingModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class FinanceModule { }
