<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence  : </span>
        <span class="title-result-show">{{ day?.code }}</span>
      </p>
      <p>
        <span class="title-show">Libellé  : </span>
        <span class="title-result-show">{{ day?.libelle }}</span>
      </p>
      <p>
        <span class="title-show">Date  : </span>
        <span class="title-result-show">{{ day?.date| date: "d MMM y" : '' : 'fr-FR' }}</span>
      </p>
    </div>
    <!-- COL MILIEU -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Trésorerie : </span>
        <span class="title-result-show">{{ day?.treasury?.nom }}</span>
      </p>
      <p>
        <span class="title-show">Solde initiale : </span>
        <span class="title-result-show text-warning">{{ day?.soldeI |number }} {{ global.device }}</span>
      </p>
      <p>
        <span class="title-show">Solde Finale : </span>
        <span class="title-result-show text-warning">{{ day?.soldeF |number }} {{ global.device }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Cloturé le : </span>
        <span class="title-result-show">{{day?.endAt| date: "d MMMM y" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ day?.end }}</span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{day?.createdAt| date: "d MMMM y" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ day?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ day?.updatedAt| date: "d MMMM y" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{day?.update }}</span>
      </p>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead>
        <tr class="text-left">
          <th>DATE</th>
          <th>LIBELLÉ</th>
          <th>RECETTE</th>
          <th>DÉPENSE</th>
          <th>SOLDE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left font-weight-bold"> {{ day?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold"> REPPORT A NOUVEAU </td>
          <td class="text-right font-weight-bold"> {{ day?.soldeI | number}} {{global.device}} </td>
          <td class="text-right font-weight-bold"></td>
          <td class="text-right font-weight-bold"></td>
        </tr>
        <!-- APPROVISIONNEMENT -->
        <tr *ngFor="let supplie of supplies; let i = index">
          <td class="text-left font-weight-bold">{{ supplie?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold">{{ supplie?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ supplie?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ supplie?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ supplie?.solde | number }} {{global.device}} </td>
        </tr>
        <!-- LOCATAIRE -->
        <tr *ngFor="let payment of payments; let i = index">
          <td class="text-left font-weight-bold">{{ payment?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold">{{ payment?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ payment?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ payment?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ payment?.solde | number }} {{global.device}} </td>
        </tr>
        <!-- CLIENT -->
        <tr *ngFor="let payment of paymentsCustomer; let i = index">
          <td class="text-left font-weight-bold">{{ payment?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold">{{ payment?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ payment?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ payment?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ payment?.solde | number }} {{global.device}} </td>
        </tr>
        <!-- FINANCEMENT -->
        <tr *ngFor="let payment of paymentsF; let i = index">
          <td class="text-left font-weight-bold">{{ payment?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold">{{ payment?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ payment?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ payment?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ payment?.solde | number }} {{global.device}} </td>
        </tr>
        <!-- DEMANDE DE FONDS -->
        <tr *ngFor="let fund of funds ; let i = index">
          <td class="text-left font-weight-bold"> {{ fund?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold"> {{ fund?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ fund?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ fund?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ fund?.solde | number }} {{global.device}} </td>
        </tr>
        <!-- DEPENSE -->
        <tr *ngFor="let spent of spents ; let i = index">
          <td class="text-left font-weight-bold"> {{ spent?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold"> {{ spent?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ spent?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ spent?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ spent?.solde | number }} {{global.device}} </td>
        </tr>
        <!-- REVERSEMENT -->
        <tr *ngFor="let rever of revers  ; let i = index">
          <td class="text-left font-weight-bold"> {{ rever?.date |date: "d MMMM y" : '' : 'fr-FR' }}</td>
          <td class="text-left font-weight-bold"> {{ rever?.libelle }} </td>
          <td class="text-right font-weight-bold">{{ rever?.recette | number : 0 }} {{global.device}}</td>
          <td class="text-right font-weight-bold">{{ rever?.depense | number : 0 }} {{global.device}} </td>
          <td class="text-right font-weight-bold">{{ rever?.solde | number }} {{global.device}} </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2" class="font-weight-bold text-right font-size-table">TOAL</td>
          <td class="text-right font-weight-bold font-size-table"> {{ totalR | number }} {{global.device}}</td>
          <td class="text-right font-weight-bold font-size-table"> {{ totalD | number }} {{global.device}}</td>
          <td class="text-right font-weight-bold font-size-table"> {{ soldeF | number }} {{global.device}}</td>
         </tr>
      </tfoot>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button (click)="printerDay(day)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
