<ng-template [ngxPermissionsOnly]="['TRUSTEE:SHOW']">
<div class="user-profile user-card mb-4">
    <div class="card-body py-0">
      <div class="user-about-block m-0">
        <div class="row">
          <div class="col-md-3 text-center mt-n5">
            <div class="change-profile text-center">
              <div class="dropdown w-auto d-inline-block" ngbDropdown>
                <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true"
                  aria-expanded="false">
                  <div class="profile-dp">
                    <div class="position-relative d-inline-block">
                        <img class="img-radius img-fluid wid-80 hei-80"
                        [src]="syndic?.photoSrc ? publicUrl+'/'+syndic?.photoSrc : 'assets/images/syndic.jpeg'"
                        onerror="this.onerror=null; this.src='assets/images/syndic.jpeg'" />
                    </div>
                    <div class="overlay">
                      <span>change</span>
                    </div>
                  </div>
                  <div class="certificated-badge">
                    <i class="fas fa-certificate text-c-blue bg-icon"></i>
                    <i class="fas fa-check front-icon text-white"></i>
                  </div>
                </a>
              </div>
            </div>
            <h5 class="mb-1">{{ syndic?.code }}</h5>
            <hr>
            <h5 class="mb-1">{{ syndic?.nom }}</h5>
            <hr>
            <h6 class="mb-1">Crée le {{ syndic?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}} par {{syndic?.create}}</h6>
          </div>
          <div class="col-md-9 mt-md-4">
            <div class="alert-{{syndic?.mandate === true ? 'warning' : syndic?.mandate === false ? 'danger' : 'secondary'}} mb-1">
              <div class="col-md-12 mt-2 text-center p-2">
                <span class="bold">
                  {{syndic?.mandate === true ? 'Mandat en cours' : syndic?.mandate === false ? 'Pas de mandat' : '' }} pour ce syndic
                </span>
              </div>
            </div>
            <hr class="border-1">
            <div class="row">
              <div class="col-md-4">Date de début du mandat : <span class="text-primary bold">{{syndic?.currentMandate?.dateD | date: "d MMMM y" : '' : 'fr-FR'}}</span></div>
              <div class="col-md-4">Date de fin du mandat : <span class="text-danger bold">{{syndic?.currentMandate?.dateF | date: "d MMMM y" : '' : 'fr-FR'}}</span></div>
              <div class="col-md-4">Temps restant : <span *ngIf="dateExiste === true" class="bold">{{this.countdown.days}} j : {{this.countdown.hours}} h : {{this.countdown.minutes}} m : {{this.countdown.seconds}} s</span></div>
            </div>
            <div class="row">
              <div class="col-md-4">Montant crédit : <span class="text-primary bold">{{ syndic?.credit|number }} XOF</span></div>
              <div class="col-md-4">Montant débit : <span class="text-primary bold">{{ syndic?.debit|number }} XOF</span></div>
              <div class="col-md-4"></div>
            </div>
            <hr class="border-1">

            <!-- MENU TABS -->
            <ul class="nav nav-tabs profile-tabs nav-fill" id="tabShowOwner" role="tablist">
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'SYNDIC'}" (click)="onChangeLoad(false, 'SYNDIC')"
                id="profil-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="profil" aria-selected="true">
                  <i class="fas fa-bars"></i> Détail
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'COPROPRIETAIRE'}" (click)="onChangeLoad(true, 'COPROPRIETAIRE')"
                  id="coproprietaire-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="coproprietaire" aria-selected="true">
                  <i class="fas fa-user"></i> Copropriétaires
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'LOT'}" (click)="onChangeLoad(true, 'LOT')"
                  id="copropriete-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="copropriete" aria-selected="true">
                  <i class="feather icon-home"></i> Lots
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'MANDAT'}" (click)="onChangeLoad(true, 'MANDAT')"
                id="mandat-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="mandat" aria-selected="false">
                  <i class="feather icon-file-text"></i> Mandats
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'BUDGET'}" (click)="onChangeLoad(true, 'BUDGET')"
                id="budget-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="budget" aria-selected="false">
                  <i class="feather icon-layers"></i> Budgets
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'APPEL'}" (click)="onChangeLoad(true, 'APPEL')"
                id="appel-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="appel" aria-selected="false">
                  <i class="fas fa-money-bill-wave"></i> Appels de charges
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'FACTURE'}" (click)="onChangeLoad(true, 'FACTURE')"
                id="facture-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="facture" aria-selected="false">
                  <i class="fas fa-clipboard"></i> Factures - Fournisseurs
                </a>
              </li>
            </ul>
            <!-- MENU TABS -->
          </div>
        </div>
      </div>
    </div>
</div>

<!-- RETOUR -->
<div class="row">
    <div class="col-sm-12 mb-2">
        <button (click)="back()" type="button" class="btn btn-secondary m-1">
            <i class="fa fa-arrow-alt-circle-left"></i> Retour
        </button>
    </div>
</div>

<!-- Filtre de recherche -->
<div class="row">
  <app-filter class="width" *ngIf="view" [name]="name" [refTitle]="refTitle" [nameTitle]="nameTitle" [bien]="bien" [bienTitle]="bienTitle" [user]="true" [userTitle]="userTitle" [etat]="etat"
    [etatRow]="etatRow" [etatTitle]="etatTitle" [categorie]="categorie" [categorieRow]="categorieRow"
    [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true" [ordre]="true" [code]="code" [count]="true" [type]="true" [typeRow]="typeRow"
    (typeEvent)="onChangeLoad(true, $event)" (filterEvent)="onFilter($event)">
  </app-filter>
</div>

<div class="row">
  <div class="col-sm-12 mb-2">
    <div class="btn-group float-right m-1 btn-outil-syndic" ngbDropdown>
      <button class="btn btn-primary mr-2" ngbDropdownToggle type="button" ngbTooltip="Modifier">
        <i class="fas fa-plus-circle"></i> Créer
      </button>
      <div ngbDropdownMenu>
        <a class="dropdown-item" (click)="add('LOT')">CONFIGURATION DES MILLIÈMES</a>
        <!-- <a class="dropdown-item" (click)="add('COPROPRIETAIRE')">COPROPRIETAIRE</a>
        <a class="dropdown-item" (click)="add('LOT')">LOT</a> -->
        <a class="dropdown-item" (click)="add('INFRASTRUCTURE')">INFRASTRUCTURE</a>
        <a class="dropdown-item" (click)="add('MANDAT')">MANDAT</a>
        <a class="dropdown-item" (click)="add('BUDGET')">BUDGET</a>
        <a *ngIf="syndic && syndic?.mode == 'MONTANT_FIXE'" class="dropdown-item" (click)="generateStaticFundsApeals(syndic?.uuid)">APPELS DE CHARGE</a>
        <a class="dropdown-item" (click)="add('PROVISION')">RÈGLEMENT</a>
        <a class="dropdown-item" (click)="add('FACTURE')">SAISIR UNE FACTURE</a>
      </div>

      <button class="btn btn-secondary" ngbDropdownToggle type="button">
          Outils <i class="fas fa-tools"></i>
      </button>
      <div class="dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" ngbTooltip="Cliquez ici pour imprimer le listing" (click)="onPrinter()">
              Imprimer <i class="feather icon-printer"></i>
          </a>
          <a class="dropdown-item" ngbTooltip="Cliquez ici pour exporter le listing" (click)="onExport()">
              Exporter <i class="fa fa-file-excel"></i>
          </a>
      </div>
    </div>
  </div>
</div>

<!-- Détail sur le syndic -->
<div *ngIf="activeTab === 'SYNDIC'">
    <app-card [hidHeader]="true" cardClass="card-datatable">
        <span class="badge badge-primary mb-4 f-14 width">
            Détails sur le syndic
        </span>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                  <div class="row py-2 item-condominium">
                    <div class="col-md-5">
                        <span>
                          Reférence
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.code}}
                        </span>
                    </div>
                  </div>
                  <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                          Nom du syndic
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.nom}}
                        </span>
                    </div>
                  </div>
                  <div class="row py-2 item-condominium">
                    <div class="col-md-5">
                        <span>
                          Pays :
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.pays?.nom }}
                        </span>
                    </div>
                  </div>
                  <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                          Ville :
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.ville }}
                        </span>
                    </div>
                  </div>
                  <div class="row py-3 item-condominium">
                    <div class="col-md-5">
                        <span>Mode de cotisation :</span>
                    </div>
                    <div class="col-md-7">
                        <span *ngIf="syndic?.mode == 'MONTANT_FIXE'" class="bold">Montant Fixe</span>
                        <span *ngIf="syndic?.mode == 'TANTIEME'" class="bold">Tantième</span>
                    </div>
                  </div>
                  <div *ngIf="syndic?.mode == 'MONTANT_FIXE'" class="row py-3">
                    <div class="col-md-5">
                        <span>Montant de la cotisation :</span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">{{syndic?.montantCotisation }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                          Commune / Quartier :
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{ syndic?.commune }} - {{ syndic?.quartier }}
                        </span>
                    </div>
                  </div>
                  <div class="row py-2 item-condominium">
                    <div class="col-md-5">
                        <span>
                          Date de création : <br />
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                        </span>
                    </div>
                  </div>
                  <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                          Créé par
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.create}}
                        </span>
                    </div>
                  </div>
                  <div class="row py-2 item-condominium">
                    <div class="col-md-5">
                        <span>
                          Date de modification : <br />
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.updatedAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                        </span>
                    </div>
                  </div>
                  <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                          Modifié par
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                          {{syndic?.update}}
                        </span>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <span class="badge badge-primary mb-4 f-14 width">
            Localisation
        </span>
        <agm-map [draggableCursor]="'default'" [mapTypeId]="'hybrid'" [latitude]="syndic?.lat" [longitude]="syndic?.lng" [zoom]="zoom">
            <agm-marker [latitude]="syndic?.lat" [longitude]="syndic?.lng"></agm-marker>
        </agm-map>

        <div class="col-md" *ngIf="syndic">
          <span class="badge badge-primary mb-4 f-14 width">Fiche de dossier</span>
          <div class="row">
            <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
              <app-folder-uploader (click)="showFile(syndic?.folder)" [folder]="syndic?.folder" [etat]="'show'"
                [allowFileUpload]="false" [allowDownload]="true">
              </app-folder-uploader>
            </div>
            <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
              <div class="row">
                <div class="col-sm-12 mb-2">
                  <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                    <i class="fa fa-arrow-alt-circle-left"></i> Fermer
                  </button>
                </div>
              </div>
              <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
              </ngx-doc-viewer>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="editSyndic(syndic)"
            class="btn btn-primary" ngbTooltip="Modifier">
            Modifier <i class="feather icon-edit-2"></i>
          </button>
          <button (click)="printSyndic(syndic)" type="submit"
            class="btn btn-warning" ngbTooltip="Imprimer">
            Imprimer <i class="feather icon-printer"></i>
          </button>
          <button (click)="deleteSyndic(Modifier)" type="button"
            class="btn btn-danger text-left" data-dismiss="modal" ngbTooltip="Supprimer">
            Supprimer <i class="feather icon-trash"></i>
          </button>
        </div>
    </app-card>
</div>
<!-- LISTE DES COPROPRIETES -->
<div *ngIf="activeTab === 'LOT'">
    <div class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width"> Liste des lotsDU SYNDIC </span>
        <app-no-data [title]="'Aucun lot trouvée'" *ngIf="rentals && rentals.length === 0 && houses && houses.length === 0"></app-no-data>
        <div *ngIf="houses && houses.length > 0 || rentals && rentals.length > 0" class="row">
            <div class="col-lg-4 col-md-4" *ngFor="let item of houses">
              <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false"
                cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                <div class="app-card-header" (click)="showHouse(item, 'HOUSE')">
                  <div class="row align-items-end">
                    <div class="col text-left pb-3">
                      <span>{{item?.owner?.nom}}</span> <br />
                    </div>
                    <div class="col text-right pb-3">
                      <span class="badge badge-light-warning ml-1">
                        <span> {{item?.type}} </span>
                      </span>
                    </div>
                  </div>
                  <div class="cover-img-block condominium-pic">
                    <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'"
                      alt="{{ item?.nom }}" title="{{ item?.nom }}" class="img-fluid">
                  </div>
                </div>
                <div class="text-center" (click)="showHouse(item, 'HOUSE')">
                  <div class="row align-items-end">
                  </div>
                  <div class="alert-info mb-1">
                    <div class="col-md-12 mt-2 text-center p-2">
                      <h5 class="bold">
                        Lot : {{item?.nom}}
                      </h5>
                    </div>
                  </div>
                  <h6 class="mb-1 mt-3">
                    Syndic : {{syndic?.nom}}
                  </h6>
                  <h6 class="mb-1 mt-3">
                    <span>
                      Superficie : {{item?.superficie}} (m²)
                    </span>
                  </h6>
                  <hr *ngIf="item?.tantiemes">
                  <h5 *ngIf="item?.tantiemes">Millième(s)</h5>
                  <h6 *ngFor="let tantieme of item?.tantiemes">
                    <span>{{tantieme?.type}} - </span>
                    <span> valeur : {{tantieme?.valeur | number}} </span>
                  </h6>
                </div>
                <hr>
                <div class="row mb-2" (click)="showHouse(item, 'HOUSE')">
                  <div class="col text-left">
                    <p class="mb-0">Code : {{item?.code}}</p>
                  </div>
                </div>
                <div class="row align-content">
                  <div class="col text-right">
                    <button (click)="showHouse(item, 'HOUSE')" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                      <i class="feather icon-edit-2"></i>
                    </button>
                    <button (click)="printHouse(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                      <i class="feather icon-printer"></i>
                    </button>
                    <button (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1"
                      ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                  </div>
                </div>
              </app-card>
            </div>
            <div class="col-lg-4 col-md-4" *ngFor="let item of rentals">
              <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false"
                cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                <div class="app-card-header" (click)="showHouse(item, 'RENTAL')">
                  <div class="row align-items-end">
                    <div class="col text-left pb-3">
                      <span>{{item?.house?.owner?.nom}}</span> <br />
                    </div>
                    <div class="col text-right pb-3">
                      <span class="badge badge-light-warning ml-1">
                        <span> {{item?.type}} </span>
                      </span>
                    </div>
                  </div>
                  <div class="cover-img-block condominium-pic">
                    <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'"
                      alt="{{ item?.nom }}" title="{{ item?.libelle }}" class="img-fluid">
                  </div>
                </div>
                <div class="text-center" (click)="showHouse(item, 'RENTAL')">
                  <div class="row align-items-end">
                  </div>
                  <div class="alert-info mb-1">
                    <div class="col-md-12 mt-2 text-center p-2">
                      <h5 class="bold">
                        Lot : {{item?.libelle}}
                      </h5>
                    </div>
                  </div>
                  <h6 class="mb-1 mt-3">
                    Syndic : {{syndic?.nom}}
                  </h6>
                  <h6 class="mb-1 mt-3">
                    <span>
                      Superficie : {{item?.superficie}} (m²)
                    </span>
                    <span>
                      - Numéro de porte : {{item?.porte}}
                    </span>
                  </h6>
                  <hr *ngIf="item?.tantiemes">
                  <h5 *ngIf="item?.tantiemes">Millième(s)</h5>
                  <h6 *ngFor="let tantieme of item?.tantiemes">
                    <span>{{tantieme?.type}} - </span>
                    <span> valeur : {{tantieme?.valeur | number}} </span>
                  </h6>
                </div>
                <hr>
                <div class="row mb-2" (click)="showHouse(item, 'RENTAL')">
                  <div class="col text-left">
                    <p class="mb-0">Code : {{item?.code}}</p>
                  </div>
                </div>
                <div class="row align-content">
                  <div class="col text-right">
                    <button (click)="showHouse(item, 'RENTAL')" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                      <i class="feather icon-edit-2"></i>
                    </button>
                    <button (click)="printHouse(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                      <i class="feather icon-printer"></i>
                    </button>
                    <button (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1"
                      ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                  </div>
                </div>
              </app-card>
            </div>
        </div>
    </div>
</div>

<!-- LISTE DES COPROPRIETAIRES -->
<div *ngIf="activeTab === 'COPROPRIETAIRE'">
    <div class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width">
            LISTE DES COPROPRIETAIRES DU SYNDIC
        </span>
        <app-no-data [title]="'Aucun propriétaire trouvé'" *ngIf="owners && owners.length === 0"></app-no-data>
        <div class="row" *ngIf="owners && owners.length > 0">
          <div class="col-md-6" *ngFor="let item of owners">
            <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
              <div class="user-about-block text-center">
                <div class="row align-items-end">
                  <div class="col text-left pb-3">
                    <span
                      class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                  </div>
                  <div class="col">
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                    <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-5x"></i>
                  </div>
                  <div class="col text-right pb-3">
                  </div>
                </div>
              </div>
              <div class="text-center">
                <a (click)="showOwner(item)" href="javascript:">
                  <h4 class="mb-1 mt-3">{{item?.nom}}</h4>
                  <p class="mb-3 text-muted">
                    <b><i class="fas fa-qrcode"></i> {{ item.code }}</b>
                    <b class="m-4"><i class="feather icon-phone"></i> {{item?.telephone}}</b>
                  </p>
                </a>
              </div>

              <div class="text-right">
                <button (click)="showOwner(item)" type="button"
                  class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button (click)="editOwner(item)" type="button"
                  class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button (click)="printerOwner(item)" type="button"
                  class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
                <button *ngIf="item?.isDelete" (click)="deleteOwner(item)" type="button"
                  class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </div>
            </app-card>
          </div>
        </div>
    </div>
</div>

<!-- LISTE DES MANDATS -->
<div *ngIf="activeTab === 'MANDAT'">
    <div class="col-md-12">
        <span class="badge badge-warning mb-4 f-14 width">
            LISTE DES MANDATS DU SYNDIC
        </span>
        <app-no-data [title]="'Aucun mandat trouvé'" *ngIf="mandats && mandats.length === 0"></app-no-data>
        <div *ngIf="mandats && mandats.length > 0">
            <app-card [hidHeader]="true" cardClass="card-datatable">
              <app-mandate-list [mandats]="mandats"></app-mandate-list>
            </app-card>
        </div>
    </div>
</div>

<!-- Liste des appels de charge -->
<div *ngIf="activeTab === 'APPEL'">
  <div class="col-md-12">
      <span class="badge badge-primary mb-4 f-14 width">
          Liste des appels de charge
          <span (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
            <i class="feather icon-info"></i>
          </span>
      </span>
      <div class="row">
        <div class="col-md-4"></div>
        <div *ngIf="isHovered" class="card card-box col-md-4 p-2">
          <h5 class="bold text-center">Formule de calcul du montant de l'appel de charges</h5>
          <p>Quota charge = (Valeur millième / Somme millièmes charge)</p>
          <p>Quota = Somme des quotas pour chaque charges</p>
          <p>Montant Appel de charge = </p>
          <p class="ml-2"> - Quota, si la périodicité du mandat est annuelle</p>
          <p class="ml-2"> - Quota / 4, si la périodicité du mandat est trimestrielle </p>
          <p class="ml-2">- Quota / 12, si la périodicité du mandat est mensuelle</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <app-no-data [title]="'Aucun appel de charge trouvé'" *ngIf="fundsapeals && fundsapeals.length === 0"></app-no-data>
      <app-funds-apeals-list *ngIf="fundsapeals && fundsapeals.length > 0" [fundsapeals]="fundsapeals" [montant]="montant" [paye]="paye" [reste]="reste"></app-funds-apeals-list>
  </div>
</div>

<!-- LISTE DES AVIS ECHEANCE -->
<div *ngIf="activeTab === 'AVIS'">
  <div class="col-md-12">
      <span class="badge badge-primary mb-4 f-14 width">
          Liste des avis d'échéances
      </span>
      <app-no-data [title]="'Aucun avis d\'écheance trouvé'" *ngIf="fundsNotices && fundsNotices.length === 0"></app-no-data>
      <app-funds-notice-list *ngIf="fundsNotices && fundsNotices.length > 0" [fundsNotices]="fundsNotices"></app-funds-notice-list>
  </div>
</div>

<!-- LISTE DES PROVISIONS -->
<div *ngIf="activeTab === 'PROVISION'">
  <div class="col-md-12">
      <span class="badge badge-primary mb-4 f-14 width">
          LISTE DES RÈGLEMENTS
      </span>
      <app-no-data [title]="'Aucun règlement trouvée'" *ngIf="fundsPayments && fundsPayments.length === 0"></app-no-data>
      <app-funds-payment-list *ngIf="fundsPayments && fundsPayments.length > 0" [fundsPayments]="fundsPayments"></app-funds-payment-list>
  </div>
</div>

<!-- LISTE DES FOURNISSEURS -->
<div *ngIf="activeTab === 'FOURNISSEUR'">
    <div class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width">
            LISTE DES FOURNISSEURS DU SYNDIC
        </span>
        <app-no-data [title]="'Aucun fournisseur trouvé'" *ngIf="providers && providers.length === 0"></app-no-data>
        <div class="row" *ngIf=" providers && providers.length > 0">
          <div class="col-md-6" *ngFor="let item of providers">
            <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
              <div class="text-center">
                <h4 class="mb-1 mt-3">{{item.nom}}</h4>
                <p class="mb-3 text-muted">
                  <i class="feather icon-phone"></i> {{item.telephone}}
                  <span class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item.type}}</span>
                </p>
                <p class="mb-1">
                  <b> {{ item.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }}  : </b> {{ item.domicile }}
                </p>
              </div>
              <div class="text-right">
                <button (click)="showProvider(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button (click)="editProvider(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button (click)="deleteProvider(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </div>
            </app-card>
          </div>
        </div>
    </div>
</div>

<!-- Liste des interventions -->
<div *ngIf="activeTab === 'INTERVENTION'">
    <div class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width">
            Liste des interventions DU SYNDIC
        </span>
        <app-no-data [title]="'Aucune intervention trouvée'" *ngIf="interventions && interventions.length === 0"></app-no-data>
        <div *ngIf="interventions && interventions.length > 0">
          <app-card [hidHeader]="true" cardClass="card-datatable">
              <div class="table-responsive">
                  <table datatable [dtOptions]="dtOptions"
                      class="table table-sm table-striped table-bordered nowrap table-hover">
                      <thead class="thead-light">
                          <tr>
                              <th>Bien</th>
                              <th>Libellé</th>
                              <th>Evolution</th>
                              <th>État</th>
                              <th>Crée le</th>
                              <th>Montant</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of interventions">
                              <td class="align-middle">
                                  <p class="m-0 d-inline-block align-middle font-16">
                                      <span class="text-primary">{{item?.trustee ? item?.trustee?.nom :''}}</span>
                                      <span *ngIf="item?.rental!=null || item?.house!=null">
                                          <br /> <span>
                                              Superficie : {{item?.rental ? item?.rental?.superficie : item?.house?.superficie}} m² -
                                              {{item?.rental? item?.rental?.piece : item?.house?.nbrPiece}} pièce(s)
                                          </span>
                                      </span>
                                      <span *ngIf="item?.houseCo!=null">
                                          <br /> <span>Copropriété : {{item?.houseCo?.nom}}</span>
                                      </span>
                                      <span *ngIf="item?.homeCo!=null">
                                          <br /> <span>Villa ou appartement : {{item?.homeCo?.nom}}</span>
                                      </span>
                                      <span *ngIf="item?.infrastructure!=null">
                                          <br /> <span>Infrastructure : {{item?.infrastructure?.nom}}</span>
                                      </span>
                                      <span *ngIf="item?.nature!=null">
                                          <br /> <span>Nature : {{item?.nature?.libelle}}</span>
                                      </span>
                                      <span *ngIf="house!=null">
                                          <br /> <span>Propriétaire
                                              : {{item?.house?.owner?.searchableTitle}}</span>
                                      </span>
                                  </p>
                              </td>
                              <td class="align-middle">
                                  <p class="m-0 d-inline-block align-middle font-16">
                                      <span class="text-primary">{{item?.nom}}</span><br /> Durée:
                                      {{timelapse(item?.dateD, item?.dateF)}} <br /> Début: {{item?.dateD|date: "d MMMM y" : '' : 'fr-FR'}} - Fin: {{item?.dateF|date: "d MMMM y" : '' : 'fr-FR'}}
                                  </p>
                              </td>
                              <td>
                                  <circle-progress [percent]="item?.pourcentage" [radius]="25" [toFixed]="1"
                                      [titleFontSize]="10" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
                                      [outerStrokeColor]="item?.pourcentage >= 65 ? '#3FBD0D' : item?.pourcentage > 35 ? '#F0DD35' : '#EC0F0F'"
                                      [innerStrokeColor]="'#95cde4'" [animation]="true" [showSubtitle]="false"
                                      [animationDuration]="300"></circle-progress>
                              </td>
                              <td>
                                  <span class="badge" [ngClass]="{
                                      'badge-danger' : item?.etat === 'STOPPER',
                                      'badge-primary' : item?.etat === 'EN COURS',
                                      'badge-warning' : item?.etat === 'PREVU',
                                      'badge-success' : item?.etat === 'TERMINER'
                                      }"> {{ item?.etat === 'EN COURS' ? 'EN COURS DE RÉALISATION' : item?.etat }}
                                  </span>
                              </td>
                              <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
                              <td>
                                  <p class="m-0 d-inline-block align-middle font-16">
                                      <span class="text-warning">{{item?.budget|number}} {{global.device}}</span>
                                  </p>
                              </td>
                              <td class="table-action">
                                <button (click)="showConstruction(item)" type="button"
                                  class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                  <i class="fas fa-eye"></i>
                                </button>
                                <button (click)="editConstruction(item)" type="button"
                                    class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                    <i class="feather icon-edit-2"></i>
                                </button>
                                <button (click)="printerConstruction(item)" type="button"
                                    class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                    <i class="feather icon-printer"></i>
                                </button>
                                <button (click)="deleteConstruction(item)" type="button" class="btn btn-icon btn-danger ml-1"
                                    ngbTooltip="Supprimer">
                                    <i class="feather icon-trash"></i>
                                </button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </app-card>
       </div>
    </div>
</div>

<!-- Liste des factures -->
<div *ngIf="activeTab === 'FACTURE'">
    <div class="col-md-12">
       <app-no-data [title]="'Aucune facture trouvée'" *ngIf="invoiceCos && invoiceCos.length === 0"></app-no-data>
       <app-invoice-co-list *ngIf="invoiceCos && invoiceCos.length > 0" [invoiceCos]="invoiceCos"></app-invoice-co-list>
    </div>
</div>

<!-- Liste des devis -->
<div *ngIf="activeTab === 'DEVIS'">
    <div class="col-md-12">
        <app-no-data [title]="'Aucun devis trouvé'" *ngIf="quotes && quotes.length === 0"></app-no-data>
       <app-quote-list *ngIf="quotes && quotes.length > 0" [quotes]="quotes"></app-quote-list>
    </div>
</div>

<!-- Liste des budgets -->
<div *ngIf="activeTab === 'BUDGET'">
    <div class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width">
            Liste des budgets DU SYNDIC
        </span>
        <app-no-data [title]="'Aucun budget trouvé'" *ngIf="budgets && budgets.length === 0"></app-no-data>
        <div class="card card-box rounded border-0" *ngIf="budgets && budgets.length > 0">
          <div class="card-body p-3">
            <div class="table-responsive m-0">
              <table datatable [dtOptions]="dtOptions" class="table table-hover text-nowrap m-0 table-with-100-percent">
                <thead>
                  <tr>
                    <th>Année d'exercice</th>
                    <th>Syndic</th>
                    <th>État</th>
                    <th>Statut</th>
                    <th>Budget</th>
                    <th>Charges réelles</th>
                    <th>Ecart</th>
                    <th>Crée le</th>
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of budgets; index as i">
                    <td>
                      <span class="text-uppercase cursor-pointer badge badge-warning">
                        ANNÉE D'EXERCICE {{item?.anneeEx}}
                      </span>
                    </td>
                    <td style="cursor: pointer;">
                      <span class="cursor-pointer" (click)="show(item,'SHOW')">
                        <p class="m-0 d-inline-block align-middle font-16">
                            <span class="text-primary">{{item?.trustee?.nom}}</span> <br />
                            {{item?.trustee?.searchableDetail}}
                        </p>
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-{{
                        item?.etat === 'TERMINE' ? 'first' :
                        item?.etat === 'VALIDE' ? 'success' :
                        item?.etat === 'ATTENTE' ? 'warning' :
                        item?.etat === 'BROUILLON' ? 'info' :
                        item?.etat === 'EN COURS' ? 'primary' : 'info'
                      }}">{{item?.etat}}</span>
                    </td>
                    <td>
                      <span class="badge badge-{{
                        item?.statut === 'ACTIF' ? 'success' :
                        item?.statut === 'TERMINE' ? 'danger' : 'info'
                      }}">{{item?.statut}}</span>
                    </td>
                    <td><span class="text-warning">{{(item?.valide != 0 ? item?.valide : item?.prevu)|number}}</span>  {{global.device}}</td>
                    <td><span class="text-danger">{{item?.charges|number}}</span>  {{global.device}}</td>
                    <td><span class="text-info">{{item?.ecart|number}}</span>  {{global.device}}</td>
                    <td>{{item.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
                    <td class="text-center">

                      <button placement="auto" ngbTooltip="Plus d'infos"
                        class="btn btn-icon btn-secondary ml-1" type="button" (click)="show(item,'SHOW')">
                        <span class="btn-wrapper--icon"><i class="fas fa-eye"></i></span>
                      </button>

                      <button *ngIf="item?.etat === 'VALIDE' " placement="auto" ngbTooltip="Clôturer le budget"
                        class="btn btn-icon btn-danger ml-1" type="button" (click)="validation(item,'CLOTURE')">
                        <span class="btn-wrapper--icon"><i class="feather icon-check-circle"></i></span>
                      </button>

                      <button *ngIf="item?.etat === 'EN COURS'" (click)="validation(item,'VALIDE')" placement="auto"
                        ngbTooltip="Valider le budget" class="btn btn-icon btn-success  ml-1" type="button">
                        <span class="btn-wrapper--icon"><i class="fas fa-check"></i></span>
                      </button>

                      <button placement="auto"  *ngIf="item?.etat != 'CLOTURE'" ngbTooltip="Saisir le budget"
                        class="btn btn-icon btn-info ml-1" type="button" (click)="show(item,'BROUILLON')">
                        <span class="btn-wrapper--icon"><i class="fas fa-cash-register"></i></span>
                      </button>
                      <button placement="auto" ngbTooltip="Modifier" *ngIf="item?.isDelete && item?.etat != 'CLOTURE'"
                        class="btn btn-icon btn-primary  ml-1" type="button" (click)="edit(item,'MODIFIER')">
                        <span class="btn-wrapper--icon"><i class="feather icon-edit-2"></i></span>
                      </button>

                      <button (click)="printerBudget(item)" type="button" class="btn btn-icon btn-warning ml-1"
                        ngbTooltip="Imprimer">
                        <span class="btn-wrapper--icon"><i class="feather icon-printer"></i></span>
                      </button>
                      <button *ngIf="item?.isDelete && item?.etat != 'CLOTURE'" placement="auto"
                        ngbTooltip="Supprimer" class="btn btn-icon btn-danger  ml-1" type="button"
                        (click)="deleteBudget(item)">
                        <span class="btn-wrapper--icon"><i class="feather icon-trash"></i></span>
                      </button>
                      <button *ngIf="item?.etat === 'VALIDE' &&  item?.trustee?.mode !== 'MONTANT_FIXE'" placement="auto" ngbTooltip="Générer les appels de charges généraux"
                        class="btn btn-icon btn-info ml-1" type="button" (click)="generateFundsApeals(item, 'GENERAL')">
                        <span class="btn-wrapper--icon"><i class="feather icon-refresh-cw"></i></span>
                      </button>

                      <button *ngIf="item?.etat === 'VALIDE' &&  item?.trustee?.mode === 'MONTANT_FIXE'" placement="auto" ngbTooltip="Générer les appels de charges"
                        class="btn btn-icon btn-info ml-1" type="button" (click)="generateStaticFundsApeals(syndic?.uuid, item?.uuid)">
                        <span class="btn-wrapper--icon"><i class="feather icon-refresh-cw"></i></span>
                      </button>
                      <button *ngIf="item?.etat === 'VALIDE' && item?.trustee?.mode !== 'MONTANT_FIXE'" placement="auto" ngbTooltip="Générer les appels de charges de réserve"
                        class="btn btn-icon btn-success ml-1" type="button" (click)="generateFundsApeals(item, 'RESERVE')">
                        <span class="btn-wrapper--icon"><i class="feather icon-refresh-cw"></i></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
</div>

<!-- LISTE DES INFRASTRUCTURES -->
<div *ngIf="activeTab === 'INFRASTRUCTURE'">
    <div class="col-md-12">
        <span class="badge badge-dark mb-4 f-14 width">
            LISTE DES INFRASTRUCTURES DU SYNDIC
        </span>
        <app-no-data [title]="'Aucune infrastructure trouvée'" *ngIf="infrastructures && infrastructures.length === 0"></app-no-data>
        <app-infrastructure-list *ngIf="infrastructures && infrastructures.length > 0" [infrastructures]="infrastructures"></app-infrastructure-list>
    </div>
</div>
</ng-template>

<ng-template [ngxPermissionsExcept]="['TRUSTEE:SHOW']">
  <div class="col-md-12">
    <app-no-droit [title]="'détails syndic'"></app-no-droit>
  </div>
</ng-template>
