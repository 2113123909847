<div class="col-md-12">
  <span class="badge badge-warning my-2 f-14 formBadge width"> Liste des types de chantier </span>
</div>
<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Libellé</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of worksites">
            <td>{{item?.numero}}</td>
            <td>{{item?.libelle}}</td>
            <td>{{item.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showWorkSite(item)" type="button" class="btn btn-icon btn-secondary ml-1"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button (click)="editWorkSite(item)" type="button" class="btn btn-icon btn-info ml-1"
                  ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button placement="auto" ngbTooltip="Voir le Dq" class="btn btn-icon btn-warning ml-1"
                  type="button" (click)="showDq(item)">
                  <span class="btn-wrapper--icon">
                    <i class="fa fa-calendar"></i>
                  </span>
                </button>
                <button type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>
  