<div class="row justify-content-center">
  <!-- Filtre de recherche -->
  <app-filter class="width" [autre]="autre" [autreTitle]="autreTitle" [autreType]="autreType" [autreClass]="autreClass"
    [autreNamespace]="autreNamespace" [autreGroups]="autreGroups" [bien]="bien" [bienTitle]="bienTitle"
    [bienType]="bienType" [bienClass]="bienClass" [bienNamespace]="bienNamespace" [bienGroups]="bienGroups"
    [libelle]="libelle" [libelleTitle]="libelleTitle" [libelleType]="libelleType" [libelleClass]="libelleClass"
    [libelleNamespace]="libelleNamespace" [libelleGroups]="libelleGroups" [dateD]="true" [dateF]="true" [type]="true"
    [typeRow]="typeRow" [isPrint]="isPrint" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)"
    (printerEvent)="onPrinter($event)">
  </app-filter>

  <div class="col-md-12 mb-3">
    <app-card [hidHeader]="true" >
      <div style="border: 1px, solid, rgb(236, 232, 232); border-radius: 2px;">
        <div class="row p-2">
          <div class="col-md-12">
            <ul class="nav nav-tabs profile-tabs nav-fill " id="tabShow" role="tablist">
              <li class="nav-item text-black">
                <a class="nav-link" [ngClass]="{'active': this.activeTab === 'VENTE'}"  id="vente-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="vente" aria-selected="true" (click)="onTabs('VENTE')">
                  Portefeuille de vente commerciale
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': this.activeTab === 'LOCATION'}"  id="location-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="location" aria-selected="true" (click)="onTabs('LOCATION')">
                  Portefeuille de location commerciale
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': this.activeTab === 'ENCAISSEMENTS'}"  id="encaissements-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="encaissements" aria-selected="true" (click)="onTabs('ENCAISSEMENTS')">
                  Encaissements
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': this.activeTab === 'ACTIONS'}"  id="actions-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="actions" aria-selected="true" (click)="onTabs('ACTIONS')">
                  Actions Commerciales 
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-md-12 mb-3" *ngIf="this.activeTab === 'VENTE'">
  </div>
  <div class="col-md-12 mb-3" *ngIf="this.activeTab === 'LOCATION'">
  </div>
  <div class="col-md-12 mb-3" *ngIf="this.activeTab === 'ENCAISSEMENTS'">
  </div>
  <div class="col-md-12 mb-3" *ngIf="this.activeTab === 'ACTIONS'">
  </div>
</div>
