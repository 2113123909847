<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
        <!-- Détails sur le locataire -->
        <div class="row">
            <span class="badge badge-secondary my-2 f-14  width">
                Détails sur le contrat
            </span>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <app-entity-finder [class]="'Tenant'" [groups]="['tenant']" [required]="true"
                      [label]="'Locataire'" (uuid)="setTenantUuid($event)"
                      [selected]="tenantSelected"
                      [disabled]="edit" [placeholder]="'Sélectionnez un locataire'">
                  </app-entity-finder>
                  <div class="invalid-feedback" *ngIf="submit && f.tenant.errors">
                    <div *ngIf="f.tenant.errors.required">{{required.novide}}</div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="!edit">
                  <div *ngIf="isLoadingContracts" class="spinner-container">
                  <div class="spinner"></div>
                  </div>

                  <label for="contract">Contrat <span class="asterix">*</span></label>
                  <select (change)="setContratUuid($event)" formControlName="contract" class="form-control"
                    id="contract" [attr.disabled]="f.tenant.value ? null : 'true'"
                    [ngClass]="{'is-invalid': submit && f.contract.errors,'is-valid': submit && f.contract.valid}">
                    <option value="null" selected> Sélectionnez un contrat</option>
                    <option *ngFor="let item of contracts" [value]="item.uuid">{{item.libelle}}</option>
                  </select>
                  <div class="invalid-feedback" *ngIf="submit && f.contract.errors">
                    <div *ngIf="f.contract.errors.required">{{required.novide}}</div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="edit">
                  <label for="contract">Contrat <span class="asterix">*</span></label>
                  <input type="text" class="form-control p-2 text-uppercase font-weight-bold text-secondary"
                  [value]="libelle" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="f.contract.value">
            <!-- Détails sur les termes du contrat -->
            <div class="row">
                <span class="badge badge-secondary my-2 f-14 width">
                    Détails sur les termes du renouvellement
                </span>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label for="pDatefin">Date de fin précédente <span class="asterix">*</span></label>
                    <input type="date" (change)="onChangeDate()" [attr.disabled]="true" formControlName="pDatefin"
                    class="form-control" id="pDatefin" [ngClass]="{
                        'is-invalid': submit && f.pDatefin.errors,
                        'is-valid': submit && f.pDatefin.valid
                    }">
                    <div class="invalid-feedback" *ngIf="submit && f.pDatefin.errors">
                    <div *ngIf="f.pDatefin.errors.required">{{required.novide}}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="dateFin">Date de fin <span class="asterix">*</span></label>
                    <input type="date" (change)="onChangeDate()" formControlName="dateFin"
                    class="form-control" id="dateFin" [ngClass]="{
                        'is-invalid': submit && f.dateFin.errors,
                        'is-valid': submit && f.dateFin.valid
                    }">
                    <div class="invalid-feedback" *ngIf="submit && f.dateFin.errors">
                    <div *ngIf="f.dateFin.errors.required">{{required.novide}}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="date">Date <span class="asterix">*</span></label>
                    <input type="date" formControlName="date" class="form-control" id="date"
                    [ngClass]="{
                        'is-invalid': submit && f.date.errors,
                        'is-valid': submit && f.date.valid
                    }">
                    <div class="invalid-feedback" *ngIf="submit && f.date.errors">
                    <div *ngIf="f.date.errors.required">{{required.novide}}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="loyer">Loyer <span class="asterix">*</span></label>
                    <input  type="number" formControlName="loyer"
                    class="form-control text-danger font-weight-bold" placeholder="Montant du loyer" [ngClass]="{
                        'is-invalid': submit && f.loyer.errors,
                        'is-valid': submit && f.loyer.valid
                    }">
                    <div class="invalid-feedback" *ngIf="submit && f.loyer.errors">
                        <div *ngIf="f.loyer.errors.required">{{required.novide}}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="charge">Charge <span class="asterix">*</span></label>
                    <input  type="number" formControlName="charge"
                    class="form-control text-danger font-weight-bold" placeholder="Montant charge" [ngClass]="{
                        'is-invalid': submit && f.charge.errors,
                        'is-valid': submit && f.charge.valid
                    }">
                    <div class="invalid-feedback" *ngIf="submit && f.charge.errors">
                        <div *ngIf="f.charge.errors.required">{{required.novide}}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="prcRetard">Pénalité cas de retard (%) <span class="asterix">*</span></label>
                    <input type="number" formControlName="prcRetard" class="form-control" min="0"
                    id="prcRetard" [ngClass]="{
                    'is-invalid': submit && f.prcRetard.errors,
                    'is-valid': submit && f.prcRetard.valid
                    }">
                    <div class="invalid-feedback" *ngIf="submit && f.prcRetard.errors">
                    <div *ngIf="f.prcRetard.errors.required">{{required.novide}}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="limite">Limite de paiement <span class="asterix">*</span></label>
                    <select formControlName="limite" class="form-control" id="limit"
                        [ngClass]="{
                        'is-invalid': submit && f.limite.errors,
                        'is-valid': submit && f.limite.valid
                        }">
                        <option *ngFor="let item of limiteRow" [value]="item.value">{{item.label}}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="submit && f.limite.errors">
                        <div *ngIf="f.limite.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="badge badge-secondary my-2 f-14 width">
                    Ajouter des options sur cette facture
                </span>
            </div>
            <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div formArrayName="options">
                      <!-- LABEL DU TABLEAU DES OPTIONS -->
                      <div *ngIf="option.controls.length > 0" class="form-group">
                        <div class="row">
                          <div class="col-md-3">
                            <label>Désignation <span class="asterix">*</span></label>
                          </div>
                          <div class="col-md-2">
                            <label>Prix unitaire <span class="asterix">*</span></label>
                          </div>
                          <div class="col-md-1">
                            <label>Qté <span class="asterix">*</span></label>
                          </div>
                          <div class="col-md-1">
                            <label>TVA</label>
                          </div>
                          <div class="col-md-2">
                            <label>Remise</label>
                          </div>
                          <div class="col-md-2">
                            <label>Total</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" *ngFor="let opt of option.controls; let i=index" [formGroupName]="i">
                        <div class="row">
                            <div class="col-md-3">
                            <input type="text" formControlName="libelle" class="form-control p-2" id="libelle{{i}}"
                                [ngClass]="{
                                'is-invalid': submit && opt.get('libelle').errors,
                                'is-valid': submit && opt.get('libelle').valid
                                }" placeholder="Désignation">
                            <div class="invalid-feedback" *ngIf="submit && opt.get('libelle').errors">
                                <div *ngIf="opt.get('libelle').errors.required">{{required.novide}}</div>
                            </div>
                            </div>
                            <div class="col-md-2">
                            <input (change)="onChangeTotal()" type="number" formControlName="prix"
                                class="form-control" id="prix{{i}}"
                                [ngClass]="{
                                'is-invalid': submit && opt.get('prix').errors,
                                'is-valid': submit && opt.get('prix').valid
                                }" placeholder="Prix unitaire">
                            <div class="invalid-feedback" *ngIf="submit && opt.get('prix').errors">
                                <div *ngIf="opt.get('prix').errors.required">{{required.novide}}</div>
                            </div>
                            <div class="invalid-feedback" *ngIf="submit && opt.get('prix').errors">
                                <div *ngIf="opt.get('prix').errors.required">{{required.novide}}</div>
                                <div *ngIf="opt.get('prix').errors.pattern">{{required.nolettre}}</div>
                            </div>
                            </div>
                            <div class="col-md-1">
                            <input (change)="onChangeTotal()" type="number" formControlName="qte"
                                class="form-control" id="qte{{i}}"
                                [ngClass]="{
                                'is-invalid': submit && opt.get('qte').errors,
                                'is-valid': submit && opt.get('qte').valid
                                }" placeholder="Quantité">
                            <div class="invalid-feedback" *ngIf="submit && opt.get('qte').errors">
                                <div *ngIf="opt.get('qte').errors.required">{{required.novide}}</div>
                                <div *ngIf="opt.get('qte').errors.pattern">{{required.nolettre}}</div>
                                <div *ngIf="opt.get('qte').errors.pattern">{{required.positive}}</div>
                            </div>
                            </div>
                            <div class="col-md-1">
                            <input (change)="onChangeTotal()" type="number" formControlName="tva"
                                    class="form-control p-2" id="tva{{i}}"
                                    [ngClass]="{
                                'is-invalid': submit && opt.get('tva').errors,
                                'is-valid': submit && opt.get('tva').valid
                                }" placeholder="TVA">
                            <div class="invalid-feedback" *ngIf="submit && opt.get('tva').errors">
                                <div *ngIf="opt.get('tva').errors.pattern">{{required.nolettre}}</div>
                                <div *ngIf="opt.get('tva').errors.pattern">{{required.positive}}</div>
                            </div>
                            </div>
                            <div class="col-md-2">
                            <input (change)="onChangeTotal()" type="number" formControlName="remise"
                                class="form-control" id="remise{{i}}">
                            </div>
                            <div class="col-md-2">
                            <input type="number" formControlName="total" class="form-control p-2" id="total{{i}}"
                                step="1000" placeholder="TOTAL" readonly>
                            <div class="invalid-feedback" *ngIf="submit && opt.get('total').errors">
                                <div *ngIf="opt.get('total').errors.pattern">{{required.nolettre}}</div>
                            </div>
                            </div>
                            <div class="col-md-1">
                            <button (click)="onDeleteOption(opt)" type="button" class="btn btn-icon btn-danger">
                                <i class="feather icon-trash"></i>
                            </button>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 d-flex flex-row-reverse">
                      <button (click)="onAddOption()" type="button" class="btn btn-primary">
                        Ajouter <i class="feather icon-plus"></i>
                      </button>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                        <table class="table table-responsive invoice-table invoice-total">
                            <tbody>
                            <tr>
                            <th>TOTAL HT :</th>
                            <td>{{ totalHT | number }}</td>
                            </tr>
                            <tr>
                            <th>TOTAL REMISE :</th>
                            <td>{{ totalRemise | number }}</td>
                            </tr>
                            <tr>
                            <th>TOTAL TVA :</th>
                            <td>{{ totalTva | number }}</td>
                            </tr>
                            <tr class="text-info">
                            <td>
                                <hr/>
                                <h2 class="text-primary m-r-10">TOTAL :</h2>
                            </td>
                            <td>
                                <hr/>
                                <h2 class="text-success">{{ totalTTC | number }}</h2>
                            </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
