<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="modal.close('ferme')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="col-md-12">
        <!-- Détails sur l'entreprise -->
        <div class="row">
          <span class="badge badge-info my-2 f-14 width">
            Détails sur l'utilisateur
          </span>
        </div>
        <div class="form-group">
          <div class="row mb-2">
            <div class="col-md-4">
              <label for="nom">Nom et prénoms <span class="asterix">*</span></label>
              <input type="text" formControlName="nom" [attr.disabled]="true" class="form-control" id="nom"
                [ngClass]="{
                  'is-invalid': submit && f.nom.errors,
                  'is-valid': submit && f.nom.valid
                }" placeholder="Nom et prénoms">
              <div class="invalid-feedback" *ngIf="submit && f.nom.errors">
                <div *ngIf="f.nom.errors.required">{{required.novide}}</div>
              </div>
            </div>                     
            <div class="col-md-4">
              <label for="email">E-mail <span class="asterix">*</span></label>
              <input type="email" [attr.disabled]="true"  formControlName="email" class="form-control" id="email"
                [ngClass]="{
                  'is-invalid': submit && f.email.errors,
                  'is-valid': submit && f.email.valid
                }" placeholder="E-mail">
              <div class="invalid-feedback" *ngIf="submit && f.email.errors">
                <div *ngIf="f.email.errors.required">{{required.novide}}</div>
                <div *ngIf="f.email.errors.pattern">{{required.noemail}}</div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="contact">Contact <span class="asterix">*</span></label>
              <input type="text" [attr.disabled]="true" formControlName="contact" class="form-control" id="contact"
                [ngClass]="{
                  'is-invalid': submit && f.contact.errors,
                  'is-valid': submit && f.contact.valid
                }" placeholder="Ex: +2250000000000">
              <div class="invalid-feedback" *ngIf="submit && f.contact.errors">
                <div *ngIf="f.contact.errors.required">{{required.novide}}</div>
              </div>
            </div>            
          </div>
        </div>
        <!-- ATTRIBUTION DES DROITS D'ACCES -->
        <div class="row">
          <span class="badge badge-info my-2 f-14 width">
            ATTRIBUTION DES MENUS D'ACCÈS
          </span>
        </div>
        <div class="form-group">
          <div class="row mb-2">
            <div class="col-md mt-4">
              <dual-list (destinationChange)="setData()" [sort]="keepSorted" [source]="source" [key]="key" [display]="display" [filter]="true"
                [(destination)]="confirmed" height="265px" [format]="format" [disabled]="disabled">
              </dual-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
        Fermer <i class="feather icon-x-circle"></i>
      </button>
      <button (click)="form.reset()" type="button" class="btn btn-warning">
        Vider <i class="fas fa-broom"></i>
      </button>
      <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
        Enregistrer <i class="feather icon-save"></i>
      </button>
    </div>
  </form>
  