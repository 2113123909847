<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-success my-2 f-14 formBadge width">
          Informations sur l'approvisionnement
        </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <label for="libelle">Libellé <span class="asterix">*</span></label>
            <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
              'is-invalid': submit && f.libelle.errors,
              'is-valid': submit && f.libelle.valid
            }" placeholder="Libellé">
            <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
              <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="motif">Motif <span class="asterix">*</span></label>
            <input type="text" formControlName="motif" class="form-control" id="motif" [ngClass]="{
              'is-invalid': submit && f.motif.errors,
              'is-valid': submit && f.motif.valid
            }" placeholder="Motif">
            <div class="invalid-feedback" *ngIf="submit && f.motif.errors">
              <div *ngIf="f.motif.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="date">Date <span class="asterix">*</span></label>
            <input type="date" formControlName="date" class="form-control" id="date" [ngClass]="{
              'is-invalid': submit && f.date.errors,
              'is-valid': submit && f.date.valid
            }">
            <div class="invalid-feedback" *ngIf="submit && f.date.errors">
              <div *ngIf="f.date.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="mode">Mode <span class="asterix">*</span></label>
            <select  formControlName="mode" (change)="onChangeLibelle()"class="form-control" id="mode"[ngClass]="{
              'is-invalid': submit && f.mode.errors,
              'is-valid': submit && f.mode.valid
            }">
            <option *ngFor="let item of modeRow" [value]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.mode.errors">
              <div *ngIf="f.mode.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4"  *ngIf="f.mode.value !== 'ESPECE'">
            <label for="source">{{ sourceTitle }}</label>
            <input type="text" formControlName="source" class="form-control" id="source" [placeholder]="sourceTitle">
          </div>
          <div class="col-md-4" *ngIf="f.mode.value !== 'ESPECE'">
            <label for="numero">{{ numeroTitle }}</label>
            <input type="text" formControlName="numero" class="form-control" id="numero"
              [placeholder]="numeroTitle">
          </div>
          <div class="col-md-4">
            <label for="montant">Montant <span class="asterix">*</span></label>
            <input type="number" formControlName="montant" step="1000" class="form-control" id="montant" [ngClass]="{
              'is-invalid': submit && f.montant.errors,
              'is-valid': submit && f.montant.valid
            }" placeholder="Montant">
            <div class="invalid-feedback" *ngIf="submit && f.montant.errors">
              <div *ngIf="f.montant.errors.required">{{required.novide}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-success my-2 f-14 formBadge width"> Informations sur la trésorerie émettrice </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <app-entity-finder [class]="'Treasury'" [groups]="['treasury']" [required]="true"
              [label]="'Trésorerie'" (uuid)="setTreasuryEmetteurUuid($event)"
              [selected]="emetteurSelected"
              [disabled]="edit || treasury" [placeholder]="'Sélectionnez une tresorerie'">
            </app-entity-finder>
            <div class="invalid-feedback" *ngIf="submit && f.emetteur.errors">
              <div *ngIf="f.emetteur.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label >Gérant <span class="asterix">*</span></label>
            <input type="text" class="form-control p-2 font-weight-bold text-success text-uppercase" readonly :value="{{ treasuryE ? treasuryE?.gerant.libelle : ''}}">
          </div>
          <div class="col-md-4">
            <label >Fond <span class="asterix">*</span></label>
            <input type="text" class="form-control p-2 font-weight-bold text-success text-uppercase" readonly :value="{{ treasuryE ? treasuryE?.solde : ''}}">
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-success my-2 f-14 formBadge width"> Informations sur la trésorerie receptrice </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <app-entity-finder [class]="'Treasury'" [groups]="['treasury']" [required]="true"
              [label]="'Trésorerie '" (uuid)="setTreasuryRecepteurUuid($event)"
              [selected]="recepteurSelected"
              [disabled]="edit" [placeholder]="'Sélectionnez une tresorerie'">
            </app-entity-finder>
            <div class="invalid-feedback" *ngIf="submit && f.recepteur.errors">
              <div *ngIf="f.recepteur.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label >Gérant <span class="asterix">*</span></label>
            <input type="text" class="form-control p-2 font-weight-bold text-success text-uppercase" readonly :value="{{ treasuryR ? treasuryR?.gerant.libelle : ''}}">
          </div>
          <div class="col-md-4">
            <label >Fond <span class="asterix">*</span></label>
            <input type="text" class="form-control p-2 font-weight-bold text-success text-uppercase" readonly :value="{{ treasuryR ? treasuryR?.solde : ''}}">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
