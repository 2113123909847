import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { environment } from '@env/environment';
import { Globals } from '@theme/utils/globals';
import HC_drilldown from 'highcharts/modules/drilldown';
import { FilterService } from '@service/filter/filter.service';
import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { dashboardPromotionService } from '@service/dashboard-promotion/dashboard-promotion.service';

HC_drilldown(Highcharts);

@Component({
  selector: 'app-dash-promotion',
  templateUrl: './dash-promotion.component.html',
  styleUrls: ['./dash-promotion.component.scss']
})
export class DashPromotionComponent implements OnInit {

  pieHouseVe = 0
  pieHouseRe = 0
  pieHouseDi = 0

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;
  public lineBasicChartOptions: any;

  widget : any
  listing : any
  echeanciers : []
  global = {country: Globals.country, device: Globals.device}
  dtOptions: any = {};
  userSession = Globals.user;
  etat: boolean = false
  isPrint: boolean = true;
  publicUrl = environment.publicUrl;
  totalTenant = 0;
  totalRentS = 0;
  totalRentA = 0;
  total = [];
  totalI = [];
  totalP = [];
  ech = [];
  echI = [];
  echP = [];
  mois = [];
  type: string = 'TOUT';
  typeRow = [
    {label: 'TOUT', value: 'TOUT'}
  ];
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    ordre: "ASC",
    type: "TOUT",
    uuid: null
  }

  nameTitle: string = "Programmes"
  name: boolean = true;
  nameType = 'ENTITY';
  nameClass= 'Promotion';
  nameNamespace= 'Client';
  nameGroups= 'promotion';

  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;

  constructor(
    private filterService: FilterService,
    private dashboardPromotionService: dashboardPromotionService,
    public router: Router,
  ) {
    this.onFilter(this.event)
    this.total = []
    this.totalP = []
    this.totalI = []
    this.ech = []
    this.echP = []
    this.echI = []
    this.mois = []
  }

  ngOnInit(): void {
    this.getPie();
    // this.getGraph();
    this.filterService.dashboard(this.event, 'promotion', null).subscribe(
      res => {
        this.getGraph();
        this.getPie();
        this.onSet(res);
    }, err => { })
    this.dtOptions = Globals.dataTable;
  }
  onFilter($event) {
    this.type = $event.type
    this.getPie();
    // this.getGraph();
    this.filterService.dashboard($event, 'promotion', null).subscribe(
      res => {
        this.onSet(res);
        this.getGraph();
        this.getPie();
    }, err => { })
  }

  onChangeLoad($event) {
    this.type = $event
    if($event === 'TOUT'){
    } else if($event === 'PROMOTION'){
    } else if($event === 'MAISON'){
    }
  }
  onSet(res){
    this.etat = res ? true : false
    this.widget = res?.widget
    this.total = []
    this.totalP = []
    this.totalI = []
    this.ech = []
    this.echP = []
    this.echI = []
    this.mois = []
    this.listing = res?.listing
    this.echeanciers = res?.echeanciers
    res?.facture.forEach(el => {
      this.total.push(el?.total)
      this.totalP.push(el?.paye)
      this.totalI.push(el?.impaye)
      this.mois.push(el?.date)
    });
    res?.echeanciers.forEach(el => {
      this.ech.push(el?.prev)
      this.echP.push(el?.reg)
      this.echI.push(el?.reg > el?.prev ? 0 : el?.prev - el?.reg)
    });
    this.pieHouseVe = res?.pieHouse.ve
    this.pieHouseRe = res?.pieHouse.re
    this.pieHouseDi = res?.pieHouse.di
  }
  getGraph() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: 'Statistique des paiements'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: 'blue',
        name: 'Total prévisionnel',
        data: this.total

      }, {
        color: 'green',
        name: 'Total recouvré',
        data: this.totalP

      }, {
        color: 'red',
        name: 'Total des impayés',
        data: this.totalI
      },]
    };


    this.lineBasicChartOptions = {
      chart: {
        type: 'spline',
      },
      colors: ['#D9F0F3', 'green', 'red'],
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Total prévisionnel',
        data: this.ech
      }, {
        name: 'Total recouvré',
        data: this.echP
      }, {
        name: 'Total des impayés',
        data: this.echI
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };
  }

  onPrinter($event){
    console.log($event)
    this.dashboardPromotionService.getPrinter($event,this.userSession?.agencyKey, this.userSession?.uuid)
  }

  getPie(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChart.nativeElement as HTMLCanvasElement).children));
      this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartData = {
        labels: ['Occupés', 'Reservés', 'Disponibles'],
        datasets: [{
          data: [this.pieHouseVe, this.pieHouseRe, this.pieHouseDi,],
          backgroundColor: ['#ff5252','#ffa21d', '#0e9e4a'],
          hoverBackgroundColor: ['#ff5252','#ffa21d', '#0e9e4a']
        }]
      };
    });
  }
}
