<div class="row justify-content-center">
  <!-- Filtre de recherche -->
  <div class="width btn-filter-provider">
    <ng-template [ngxPermissionsOnly]="['PROVIDER:LIST', 'PRODUCT:LIST']">
      <app-filter class="width" [name]="true" [nameTitle]="nameTitle" [user]="true" [userTitle]="userTitle"
        [etat]="true" [etatRow]="etatRow" [etatTitle]="etatTitle" [categorie]="categorie" [categorieRow]="categorieRow"
        [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true" [min]="true"
        [minTitle]="minTitle" [max]="true" [maxTitle]="maxTitle" [ordre]="true" [code]="true" [count]="true"
        [type]="true" [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
      </app-filter>
    </ng-template>
  </div>
  <ng-template [ngxPermissionsExcept]="['PROVIDER:LIST', 'PRODUCT:LIST']">
    <div class="col-md-12">
      <app-no-droit [title]="'prestataire'"></app-no-droit>
    </div>
  </ng-template>

  <!-- BOUTONS DE GESTION -->
  <div class="col-sm-12 mb-4 btn-all-provider">
    <div class="first">
      <div class="second">
        <div class="btn-provider">
          <button *ngxPermissionsOnly="['PROVIDER:NEW']" (click)="addProvider()" type="button"
            class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter un prestataire">
            Créer un Tiers <i class="fas fa-truck"></i>
          </button>
        </div>
        <div class="btn-provider">
          <button *ngxPermissionsOnly="['PROVIDER:CONTRACT:NEW']" (click)="addProviderContract()" type="button"
            class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter un contrat">
            Créer un Contrat <i class="fas fa-truck"></i>
          </button>
        </div>
        <div class="btn-group float-right m-1" ngbDropdown>
          <button *ngxPermissionsOnly="['PRODUCT:NEW']" class="btn btn-info" ngbDropdownToggle type="button">
            Resssource <i class="fas fa-hammer"></i>
          </button>
          <div class="dropdown-menu-right" ngbDropdownMenu>
            <a class="dropdown-item" (click)="addFamily()" ngbTooltip="Cliquez ici pour ajouter une famille">
              Créer une Famille
            </a>
            <a class="dropdown-item" (click)="addSubfamily()" ngbTooltip="Cliquez ici pour ajouter une sous-famille">
              Créer une Sous famille
            </a>
            <a class="dropdown-item" (click)="addProduct()" ngbTooltip="Cliquez ici pour ajouter une ressource">
              Créer une Ressource
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-group float-right m-1 btn-outil-provider" ngbDropdown>
      <button class="btn btn-warning" ngbDropdownToggle type="button">
        Outils <i class="fas fa-tools"></i>
      </button>
      <div class="dropdown-menu-right" ngbDropdownMenu>
        <a *ngxPermissionsOnly="['PROVIDER:PRINTER:LIST']" class="dropdown-item" (click)="onPrinter()"
          ngbTooltip="Cliquez ici pour imprimer le listing">
          Imprimer <i class="feather icon-printer"></i>
        </a>
        <a class="dropdown-item" (click)="onExport()" ngbTooltip="Cliquez ici pour exporter le listing">
          Exporter <i class="fa fa-file-excel"></i>
        </a>
        <a class="dropdown-item" (click)="onImport()" ngbTooltip="Cliquez ici pour importer un fichier">
          Importer <i class="feather icon-download"></i>
        </a>
        <a class="dropdown-item" (click)="onModel()" ngbTooltip="Cliquez ici pour génerer un modèle">
          Génerer <i class="feather icon-upload"></i>
        </a>
      </div>
    </div>
  </div>

  <!-- Liste des pestataires -->
  <div class="width list-provider">
    <ng-template ngxPermissionsOnly="PROVIDER:LIST">
      <div class="col-md-12" *ngIf="type === 'PRESTATAIRE'">
        <span class="badge badge-primary my-2 f-14 formBadge width"> Liste des pestataires </span>
        <app-no-data [title]="'Aucun prestataire trouvé'"
          *ngIf="type === 'PRESTATAIRE' && providers && providers.length === 0"></app-no-data>
      </div>
      <div class="col-md-12" *ngIf="type === 'PRESTATAIRE' && providers && providers.length > 0">
        <div class="row">
          <div class="col-md-6" *ngFor="let item of providers">
            <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
              <div class="text-center">
                <h4 class="mb-1 mt-3">{{item.nom}}</h4>
                <p class="mb-3 text-muted">
                  <i class="feather icon-phone"></i> {{item.telephone}}
                  <span class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item.type}}</span>
                </p>
                <p class="mb-1">
                  <b> {{ item.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }} : </b> {{ item.domicile }}
                </p>
                <h5 class="mb-1 mt-3">Solde <span class="badge badge-warning">
                    {{ item?.solde | number }} XOF
                  </span></h5>
              </div>
              <div class="text-right">
                <button *ngxPermissionsOnly="'PROVIDER:SHOW'" (click)="showProvider(item)" type="button"
                  class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button *ngxPermissionsOnly="'PROVIDER:EDIT'" (click)="editProvider(item)" type="button"
                  class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'PROVIDER:DELETE'" (click)="delete(item)" type="button"
                  class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </div>
            </app-card>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template ngxPermissionsExcept="PROVIDER:LIST" [ngxPermissionsOnly]="['PRODUCT:LIST']">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'PRESTATAIRE'" [title]="'liste prestataire'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES PRODUITS -->
  <ng-template ngxPermissionsOnly="PRODUCT:LIST">
    <div class="col-md-12" *ngIf="type === 'PRODUIT'">
      <span class="badge badge-warning mb-4 f-14 width">
        LISTE DES RESSOURCES
      </span>
      <app-no-data [title]="'Aucun produit trouvé'"
        *ngIf="type === 'PRODUIT' && products && products.length === 0"></app-no-data>
    </div>
    <app-product-list [products]="products" *ngIf="type === 'PRODUIT' && products && products.length > 0" class="width">
    </app-product-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="PRODUCT:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'PRODUIT'" [title]="'liste produit'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES FAMILLES -->
  <ng-template ngxPermissionsOnly="FAMILY:LIST">
    <div class="col-md-12" *ngIf="type === 'FAMILY'">
      <span class="badge badge-info mb-4 f-14 width">
        LISTE DES FAMILLES
      </span>
      <app-no-data [title]="'Aucun produit trouvé'"
        *ngIf="type === 'FAMILY' && familys && familys.length === 0"></app-no-data>
    </div>
    <app-family-list [familys]="familys" *ngIf="type === 'FAMILY' && familys && familys.length > 0" class="width">
    </app-family-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="FAMILY:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'FAMILY'" [title]="'liste des familles'"></app-no-droit>
    </div>
  </ng-template>


  <!-- LISTE SOUS FAMILLE -->
  <ng-template ngxPermissionsOnly="SUBFAMILY:LIST">
    <div class="col-md-12" *ngIf="type === 'SUBFAMILY'">
      <span class="badge badge-info mb-4 f-14 width">
        LISTE DES SOUS FAMILLE
      </span>
      <app-no-data [title]="'Aucun produit trouvé'"
        *ngIf="type === 'SUBFAMILY' && subFamilys && subFamilys.length === 0"></app-no-data>
    </div>
    <app-sub-family-list [subFamilys]="subFamilys" *ngIf="type === 'SUBFAMILY' && subFamilys && subFamilys.length > 0"
      class="width">
    </app-sub-family-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="SUBFAMILY:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'SUBFAMILY'" [title]="'liste des sous-famille'"></app-no-droit>
    </div>
  </ng-template>

  <!-- Liste des contrats -->
  <ng-template ngxPermissionsOnly="PROVIDER:CONTRACT:LIST">
    <div class="col-md-12" *ngIf="type === 'CONTRAT'">
      <span class="badge badge-info mb-4 f-14 width" *ngIf="providerContracts && providerContracts.length === 0">
        Liste des contrats
      </span>
      <app-no-data [title]="'Aucun contrat trouvé'"
        *ngIf="type === 'CONTRAT' && providerContracts && providerContracts.length === 0"></app-no-data>
    </div>
    <app-provider-contract-list [providerContracts]="providerContracts"
      *ngIf="type === 'CONTRAT' && providerContracts && providerContracts.length > 0" class="width">
    </app-provider-contract-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="PROVIDER:CONTRACT:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'CONTRAT'" [title]="'liste des contrats'"></app-no-droit>
    </div>
  </ng-template>
</div>