<div class="container">
  <div class="row">
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('PAIEMENT')" type="button" class="btn btn-secondary w-100">
        Paiements loyers <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.pay : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('PAIEMENT-VENTE')" type="button" class="btn btn-secondary w-100">
        Paiements liés aux ventes <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.payCl : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('CONTRAT')" type="button" class="btn btn-secondary w-100">
        Contrats <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.cont : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('FOLDER')" type="button" class="btn btn-secondary w-100">
        Contrats de vente <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.fol : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('MANDAT')" type="button" class="btn btn-secondary w-100">
        Mandat De Gestion <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.mandat : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('REVERSEMENT')" type="button" class="btn btn-secondary w-100">
        Reversement <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.repay : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('ETAT')" type="button" class="btn btn-secondary w-100">
        État des lieux <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.inv : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('RESILIATION')" type="button" class="btn btn-secondary w-100">
        Contrat  de location à résilier  <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.resili : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('RESILIATION-DOSSIER')" type="button" class="btn btn-secondary w-100">
        Contrats de vente à résilier <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.resiliF : 0}}</b></span>
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('RENOUVELLEMENT')" type="button" class="btn btn-secondary w-100">
        Contrat à rénouveler<span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.renew : 0}}</b></span>
      </button>
    </div>
    <!-- <div class="col-md-3 mb-3">
      <button (click)="onChangeLoad('MUTATION')" type="button" class="btn btn-secondary w-100">
        Mutation De Dossier <span class="badge badge-warning ml-3"><b>{{this.widget ? this.widget.mutation : 0}}</b></span>
      </button>
    </div> -->
  </div>

  <div class="row justify-content-center">
    <!-- Filtre de recherche -->
    <app-filter class="width"
      [name]="name"
      [nameTitle]="nameTitle"
      [nameType]="nameType"
      [nameClass]="nameClass"
      [nameNamespace]="nameNamespace"
      [nameGroups]="nameGroups"

      [autre]="autre"
      [autreTitle]="autreTitle"
      [autreType]="autreType"
      [autreClass]="autreClass"
      [autreNamespace]="autreNamespace"
      [autreGroups]="autreGroups"

      [bien]="bien"
      [bienTitle]="bienTitle"
      [bienType]="bienType"
      [bienClass]="bienClass"
      [bienNamespace]="bienNamespace"
      [bienGroups]="bienGroups"

      [dateD]="true"
      [dateF]="true"
      [type]="true"
      [typeDisabled]="true"
      [typeDefault]="default"
      [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
    </app-filter>

    <!-- LISTE DES PAYEMENTS -->
    <div class="col-md-12"  *ngIf="type === 'PAIEMENT'">
      <span class="badge badge-success my-2 f-14 formBadge width">
        Liste des paiements
      </span>
      <app-no-data class="width" [title]="'Aucun paiement trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-payment-list class="width" [payments]="datas" [locataire]="false" [validate]="true"  *ngIf="type === 'PAIEMENT' && datas && datas.length > 0">
    </app-payment-list>

    <!-- LISTE DES PAYEMENTS CLIENT-->
    <div class="col-md-12" *ngIf="type === 'PAIEMENT-VENTE'">
      <span class="badge badge-success my-2 f-14 formBadge width">
        Liste des paiements
      </span>
      <app-no-data class="width" [title]="'Aucun paiement trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-payment-customer-list class="width" [payments]="datas" *ngIf="type === 'PAIEMENT-VENTE' && datas && datas.length > 0" [validate]="true"></app-payment-customer-list>

    <!-- Liste des dossiers -->
    <div class="col-md-12" *ngIf="type === 'FOLDER'">
      <span class="badge badge-secondary mb-4 f-14 width">
        Liste des dossiers
      </span>
      <app-no-data class="width" [title]="'Aucun dossier trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-folder-list class="width" [folders]="datas" *ngIf="type === 'FOLDER' && datas && datas.length > 0" [validate]="true"></app-folder-list>

    <!-- Liste des contrats -->
    <div class="col-md-12"  *ngIf="type === 'CONTRAT'">
      <span class="badge badge-secondary my-2 f-14 formBadge width">
        Liste des contrats
      </span>
      <app-no-data class="width" [title]="'Aucun contrat trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-contract-list class="width" [contracts]="datas" [locataire]="false"  [validate]="true" *ngIf="type === 'CONTRAT' && datas && datas.length > 0">
    </app-contract-list>

    <!-- LISTE DES ETAT DES LIEUX -->
    <div class="col-md-12" *ngIf="type === 'ETAT'">
      <span class="badge badge-info my-2 f-14 formBadge width">
        Liste des états des lieux
      </span>
      <app-no-data class="width" [title]="'Aucun etat de lieux trouvé'" *ngIf="type === 'ETAT' && datas && datas.length === 0"></app-no-data>
    </div>
    <app-inventory-list class="width" [action]="true" [inventories]="datas" [locataire]="false" [validate]="true"
      *ngIf="datas && datas.length > 0">
    </app-inventory-list>

    <!-- Liste des renouvellements-->
    <div class="col-md-12" *ngIf="type === 'RENOUVELLEMENT'">
      <span class="badge badge-success my-2 f-14 formBadge width">
        Liste des renouvellements
      </span>
      <app-no-data class="width" [title]="'Aucun renouvellement trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-renew-contract-list class="width" [renews]="datas" [locataire]="false" [validate]="true" *ngIf="type === 'RENOUVELLEMENT' && datas && datas.length > 0">
    </app-renew-contract-list>

    <!-- Liste des résiliation-->
    <div class="col-md-12" *ngIf="type === 'RESILIATION'">
      <span class="badge badge-secondary my-2 f-14 formBadge width">
        Liste des contrat à résilier
      </span>
      <app-no-data class="width" [title]="'Pas de résiliation de contrat détectée'" *ngIf="type === 'RESILIATION' && datas && datas.length === 0"></app-no-data>
    </div>
    <app-terminate-list class="width" [locataire]="false" [validate]="true" [terminates]="datas" *ngIf="datas && datas.length > 0">
    </app-terminate-list>

    <!-- LISTE DES MANDATS -->
    <div class="col-md-12" *ngIf="type === 'MANDAT'">
      <span class="badge badge-warning mb-4 f-14 width">
        Liste des mandats
      </span>
      <app-no-data [title]="'Aucun mandat trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-mandate-list [mandates]="datas" *ngIf="type === 'MANDAT' && datas && datas.length > 0" [validate]="true" class="width">
    </app-mandate-list>

    <!-- LISTE DES REVERSEMENTS -->
    <div class="col-md-12" *ngIf="type === 'REVERSEMENT'">
      <span class="badge badge-success mb-4 f-14 width">
        Liste des reversements
      </span>
      <app-no-data [title]="'Aucun réversement trouvé'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-repayment-list [repayments]="datas" *ngIf="type === 'REVERSEMENT' && datas && datas.length > 0" [validate]="true"
      class="width">
    </app-repayment-list>


    <!-- Liste des résiliations de dossier -->
    <div class="col-md-12" *ngIf="type === 'RESILIATION-DOSSIER'">
      <span class="badge badge-secondary my-2 f-14 formBadge width">
        Liste des résiliations de dossier
      </span>
      <app-no-data [title]="'Aucune résiliation de dossier trouvée'" *ngIf="datas && datas.length === 0"></app-no-data>
    </div>
    <app-folder-terminate-list class="width" [terminates]="datas" [validate]="true" *ngIf="type === 'RESILIATION-DOSSIER' && datas && datas.length > 0"></app-folder-terminate-list>
  </div>
</div>
