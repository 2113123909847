import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EtatFinanceComponent } from './etat-finance/etat-finance.component';

const routes: Routes = [
  { path: "etat-financier", component: EtatFinanceComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinanceRoutingModule { }
