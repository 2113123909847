<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <!-- DÉTAILS SUR LE TICKET -->
      <div class="row">
        <span class="badge badge-primary my-2 f-14 width">
          Détails sur le locataire et le ticket
        </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <label for="type">Type <span class="asterix">*</span></label>
            <select formControlName="type" class="form-control" id="type" (change)="onTypeChange($event.target.value)"
              [ngClass]="{
              'is-invalid': submit && f.type.errors,
              'is-valid': submit && f.type .valid
            }">
              <option *ngFor="let item of typesRow" [value]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.type.errors">
              <div *ngIf="f.type.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label>{{selectedType.label}} <span class="asterix">*</span></label>
            <app-entity-finder [class]="selectedType.className" [groups]="[selectedType.groupName]" [required]="true"
              [selected]="emitterSelected" [disabled]="this.edit" (uuid)="setEmitterUuid($event)">
            </app-entity-finder>
          </div>
          <div class="col-md-4">
            <label>Services <span class="asterix">*</span></label>
            <app-entity-finder [class]="'Service'" [namespace]="'ADMIN'" [groups]="['service']" [required]="true"
              [selected]="serviceSelected" [disabled]="this.edit" (uuid)="setServiceUuid($event)">
            </app-entity-finder>
          </div>
          <div class="col-md-4">
            <label for="user">Commercial <span class="asterix">*</span></label>
            <select formControlName="user" class="form-control" id="user" type=text [ngClass]="{
              'is-invalid': submit && f.user.errors,
              'is-valid': submit && f.user .valid
            }">
              <option *ngFor="let item of users" [value]="item.id">{{item.nom}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.user.errors">
              <div *ngIf="f.user.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label>Catégorie <span class="asterix">*</span></label>
            <app-entity-finder [class]="'Category'" [groups]="['category']" [required]="true"
              [selected]="categorySelected" [disabled]="this.edit" (uuid)="setCategoryUuid($event)">
            </app-entity-finder>
          </div>
          <div class="col-md-4">
            <label for="urgence">Niveau d'urgence <span class="asterix">*</span></label>
            <select formControlName="urgence" class="form-control" id="urgence" [ngClass]="{
              'is-invalid': submit && f.urgence.errors,
              'is-valid': submit && f.urgence.valid
            }">
              <option *ngFor="let item of urgenceRow" [value]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.urgence.errors">
              <div *ngIf="f.urgence.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="date">Date<span class="asterix">*</span></label>
            <input formControlName="date" class="form-control" id="date" type="date" [ngClass]="{
              'is-invalid': submit && f.date.errors,
              'is-valid': submit && f.date.valid
            }">
            <div class="invalid-feedback" *ngIf="submit && f.date.errors">
              <div *ngIf="f.date.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-8">
            <label for="libelle">Objet<span class="asterix">*</span></label>
            <input formControlName="objet" class="form-control" id="objet" type="text" [ngClass]="{
              'is-invalid': submit && f.objet.errors,
              'is-valid': submit && f.objet.valid
            }">
            <div class="invalid-feedback" *ngIf="submit && f.objet.errors">
              <div *ngIf="f.objet.errors.required">{{required.novide}}</div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-12">
            <label for="description">Description <span>*</span></label>
            <div class="col">
              <app-tinymce #tinymce='tinymce' formControlName="description" id="description"></app-tinymce>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-12">
            <label>Pièces et documents à joindre</label>
            <app-folder-uploader (filesd)="files($event)" [maxSize]="3" (filesUploaded)="setParam('folderUuid',$event)"
              [path]="'ticket'" [etat]="edit ? 'edit': 'add'" [folder]="edit && ticket ? ticket?.folder : null">
            </app-folder-uploader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
