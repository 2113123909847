<div class="user-profile user-card mb-4" *ngIf="owner">
  <div class="card-body py-0">
    <div class="user-about-block m-0">
      <div class="row">
        <div class="col-md-3 text-center mt-n5">
          <div class="change-profile text-center">
            <div class="dropdown w-auto d-inline-block" ngbDropdown>
              <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true"
                aria-expanded="false">
                <div class="profile-dp">
                  <div class="position-relative d-inline-block">
                    <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Masculin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Féminin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                    <img *ngIf="owner?.type === 'ENTREPRISE' && owner?.photoSrc"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <i *ngIf="owner?.type === 'ENTREPRISE' && !owner?.photoSrc" class="fas fa-building fa-5x"></i>
                  </div>
                  <div class="overlay">
                    <span>change</span>
                  </div>
                </div>
                <div class="certificated-badge">
                  <i class="fas fa-certificate text-c-blue bg-icon"></i>
                  <i class="fas fa-check front-icon text-white"></i>
                </div>
              </a>
            </div>
          </div>
          <h5 class="mb-1">{{ owner?.nom }}</h5>
          <p class="mb-2 text-muted text-uppercase">{{ owner?.type }}</p>
        </div>
        <div class="col-md-9 mt-md-4">
          <div class="row">
            <div class="col-md-3">
              <p class="mb-1 text-muted d-flex align-items-end text-h-primary">
                <i class="fas fa-business-time mr-2"></i>{{owner?.profession}}
              </p>
              <div class="clearfix"></div>
              <p class="mb-1 text-muted d-flex align-items-end text-h-primary">
                <i class="feather icon-phone mr-2 f-18"></i>{{owner?.telephone}}
              </p>
            </div>
            <div class="col-md-3">
              <div class="media">
                <i class="feather icon-map-pin mr-2 mt-1 f-18"></i>
                <div class="media-body">
                  <p class="mb-0 text-muted">{{owner?.domicile}}</p>
                </div>
              </div>
              <div class="media">
                <i class="feather icon-mail mr-2 f-18"></i>
                <div class="media-body">
                  <p class="mb-0 text-muted">{{owner?.email}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="media">
                <div class="media-body">
                  <p class="mb-0 text-muted text-danger font-size-table font-weight-bold">Brut à reverser : {{ owner?.impaye | number }} {{global.device}}</p>
                  <p class="mb-0 text-muted text-warning font-size-table font-weight-bold">Déjà perçu : {{ owner?.reverse | number }} {{global.device}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- MENU TABS PROPRIETAIRE -->
          <ul class="nav nav-tabs profile-tabs nav-fill" id="tabShowOwner" role="tablist">
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'DASHBOARD'}"
                (click)="onChangeLoad('DASHBOARD')" id="dashboard-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="dashboard" aria-selected="true">
                <i class="feather icon-bar-chart-2"></i> Tableau de bord
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'PROPRIETAIRE'}"
                (click)="onChangeLoad('PROPRIETAIRE')" id="profil-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="profil" aria-selected="true">
                <i class="far fa-user"></i> Profil
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'BIEN'}" (click)="onChangeLoad('BIEN')"
                id="bien-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="bien" aria-selected="true">
                <i class="feather icon-home"></i> Bien
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'LOCATIVE'}"
                (click)="onChangeLoad('LOCATIVE')" id="locative-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="locative" aria-selected="false">
                <i class="fas fa-home"></i> Locative
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'MANDAT'}"
                (click)="onChangeLoad('MANDAT')" id="mandat-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="mandat" aria-selected="false">
                <i class="feather icon-file-text"></i> Mandat
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'REVERSEMENT'}"
                (click)="onChangeLoad('REVERSEMENT')" id="reversement-tab" data-toggle="tab" href="javascript:"
                role="tab" aria-controls="reversement" aria-selected="false">
                <i class="fas fa-money-bill-wave"></i> Reversement
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'NOTE_INTERNE'}"
                (click)="onChangeLoad('NOTE_INTERNE')" id="note-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="note" aria-selected="true">
                <i class="mr-2 feather icon-message-square"></i> Note interne
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- RETOUR -->
<div class="row">
  <div class="col-sm-12 mb-2">
    <button (click)="back()" type="button" class="btn btn-secondary m-1">
      <i class="fa fa-arrow-alt-circle-left"></i> Retour
    </button>
  </div>
</div>

<!-- Filtre de recherche -->
<div class="row" *ngIf="activeTab !== 'PROPRIETAIRE'">
  <ng-template
    [ngxPermissionsOnly]="['HOUSE:LIST', 'RENTAL:LIST', 'MANDATE:LIST', 'PAYMENT:REPAYMENT:LIST', 'REPAYMENT:LIST', 'RENEW:MANDATE:LIST']">
    <app-filter class="width"
      [name]="name"
      [nameTitle]="nameTitle"
      [nameType]="nameType"
      [nameClass]="nameClass"
      [nameNamespace]="nameNamespace"
      [nameGroups]="nameGroups"

      [autre]="autre"
      [autreTitle]="autreTitle"
      [autreType]="autreType"
      [autreClass]="autreClass"
      [autreNamespace]="autreNamespace"
      [autreGroups]="autreGroups"

      [bien]="bien"
      [bienTitle]="bienTitle"
      [bienType]="bienType"
      [bienClass]="bienClass"
      [bienNamespace]="bienNamespace"
      [bienGroups]="bienGroups"

      [user]="true"
      [userTitle]="userTitle"
      [etat]="true"
      [etatRow]="etatRow"
      [etatTitle]="etatTitle"
      [categorie]="true"
      [categorieRow]="categorieRow"
      [categorieTitle]="categorieTitle"
      [dateD]="true"
      [dateF]="true" [create]="true" [min]="true" [minTitle]="minTitle"
      [max]="true" [maxTitle]="maxTitle" [ordre]="true" [code]="true" [count]="true" [type]="true" [typeRow]="typeRow"
      [uuid]="owner.uuid" (filterEvent)="onFilter($event)">
    </app-filter>
  </ng-template>
  <ng-template
    [ngxPermissionsExcept]="['HOUSE:LIST', 'RENTAL:LIST', 'MANDATE:LIST', 'PAYMENT:REPAYMENT:LIST', 'REPAYMENT:LIST', 'RENEW:MANDATE:LIST']">
    <div class="col-md-12">
      <app-no-droit [title]="'propriétaire'"></app-no-droit>
    </div>
  </ng-template>
</div>

<!-- AFFICHAGE DU TABS -->
<div class="row">
  <div class="col-md-12 order-md-2">
    <div class="tab-content" id="myTabContent">

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'DASHBOARD'}" id="dashboard" role="tabpanel" aria-labelledby="profil-tab">
        <div class="row justify-content-center">
          <div class="col-xl-3 col-md-6">
            <app-card [hidHeader]="true">
              <div class="row align-items-center m-l-0">
                <div class="col-auto"> <i class="icon feather icon-users f-30 text-c-red"></i> </div>
                <div class="col-auto">
                  <h6 class="text-muted m-b-10">Bien(s)</h6>
                  <h2 class="m-b-0">9038</h2>
                </div>
              </div>
            </app-card>
          </div>
          <div class="col-xl-3 col-md-6">
            <app-card [hidHeader]="true">
              <div class="row align-items-center m-l-0">
                <div class="col-auto">
                  <i class="fas fa-home f-30 text-c-green"></i>
                </div>
                <div class="col-auto">
                <h6 class="text-muted m-b-10">Locative </h6>
                <h2 class="m-b-0">39930</h2>
                </div>
              </div>
            </app-card>
          </div>
          <div class="col-xl-3 col-md-6">
            <app-card [hidHeader]="true">
              <div class="row align-items-center m-l-0">
                <div class="col-auto">
                  <i class="icon feather icon-file-text f-30 text-c-yellow"></i>
                </div>
                <div class="col-auto">
                  <h6 class="text-muted m-b-10">Mandats</h6>
                  <h2 class="m-b-0">2902</h2>
                </div>
              </div>
            </app-card>
          </div>
          <div class="col-xl-3 col-md-6">
            <app-card [hidHeader]="true">
              <div class="row align-items-center m-l-0">
                <div class="col-auto">
                  <i class="icon feather icon-credit-card f-30 text-c-blue"></i>
                </div>
                <div class="col-auto">
                  <h6 class="text-muted m-b-10">Reversements</h6>
                  <h2 class="m-b-0">9309</h2>
                </div>
              </div>
            </app-card>
          </div>

          <div class="col-md-7">
            <div class="row">
              <div class="col-sm-6">
                <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h4 class="text-c-red">67</h4>
                      <h6 class="text-muted m-b-0">Bien en Ventes</h6>
                    </div>
                    <div class="col-4 text-right"></div>
                  </div>
                  <div class="app-card-footer">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <p class="text-white m-b-0">45  Vendus</p>
                      </div>
                      <div class="col-4">
                        <p class="text-white m-b-0">736 Réservées</p>
                      </div>
                      <div class="col-4">
                        <p class="text-white m-b-0">98 Disponibles</p>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>
              <div class="col-sm-6">
                <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h4 class="text-c-yellow">90</h4>
                      <h6 class="text-muted m-b-0">Locations</h6>
                    </div>
                    <div class="col-4 text-right"> </div>
                  </div>
                  <div class="app-card-footer">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <p class="text-white m-b-0">908 Occupées</p>
                      </div>
                      <div class="col-4">
                        <p class="text-white m-b-0">3 Réservées</p>
                      </div>
                      <div class="col-4 ">
                        <p class="text-white m-b-0">30 Disponibles</p>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>
              <div class="col-md-12 ">
                <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions" style="width: 100%; height: 400px; display: block;"></lib-highcharts-angular>
              </div>
            </div>
          </div>

          <div class="col-md-5 mt-4">
            <app-card cardTitle="Taux de recouvrement des loyers" [options]="false">
              <div #doughnutChart>
                <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData" style="height: 345px;"></chart>
              </div>
            </app-card>
          </div>

          <div class="col-md-12 mt-4">
            <app-card [hidHeader]="true">
              <div class="col-md-12">
                <span class="badge badge-danger my-2 f-14 formBadge width"> Tableau récapitulatif des occupants </span>
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>OCCUPANT</th>
                        <th>BIEN</th>
                      </tr>
                    </thead>
                  </table>
                </div>
            </div>
            </app-card>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'PROPRIETAIRE'}" id="profil" role="tabpanel"
        aria-labelledby="profil-tab">
        <div class="card">
          <div class="col-md-12 mt-2 text center">
            <div class="d-flex">
              <!-- COL GAUCHE -->
              <div class="flex-fill">
                <p>
                  <span class="title-show">Référence : </span>
                  <span class="title-result-show">{{ owner?.code }}</span>
                </p>
                <p>
                  <span class="title-show">Numéro IFU : </span>
                  <span class="title-result-show">{{ owner?.ifus }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? 'Raison sociale' : 'Nom et prénoms' }} :
                  </span>
                  <span class="title-result-show">
                    {{ owner?.type === 'ENTREPRISE' ? owner?.nom : owner?.civilite + ' ' + owner?.nom }}
                  </span>
                </p>
                <p>
                  <span class="title-show">Type : </span>
                  <span class="title-result-show">{{ owner?.type }}</span>
                </p>
                <p>
                  <span class="title-show">Émail : </span>
                  <span class="title-result-show">{{ owner?.email }}</span>
                </p>
                <p>
                  <span class="title-show">Téléphone : </span>
                  <span class="title-result-show">{{ owner?.telephone }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }} :
                  </span>
                  <span class="title-result-show">
                    {{ owner?.type === 'ENTREPRISE' ? owner?.siegeSocial : owner?.domicile }}
                  </span>
                </p>
                <p>
                  <span class="title-show">Code postal : </span>
                  <span class="title-result-show">{{ owner?.codePostal }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? "Domaine d'activité" : 'Profession' }} :
                  </span>
                  <span class="title-result-show">{{ owner?.profession }}</span>
                </p>
                <p *ngIf="owner?.type === 'ENTREPRISE'">
                  <span class="title-show">Capital : </span>
                  <span class="title-result-show">{{ owner?.capital }}</span>
                </p>
              </div>
              <!-- COL MILLIEU -->
              <div class="flex-fill">
                <p *ngIf="owner?.type === 'ENTREPRISE'">
                  <span class="title-show">Nom du responsable: </span>
                  <span class="title-result-show">{{ owner?.civilite + ' ' + owner?.nomResponsable }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? 'Date naissance du responsable' : 'Date naissance' }} :
                  </span>
                  <span class="title-result-show">{{ owner?.dateN | date: "d MMMM y" : '' : 'fr-FR' }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? 'Lieu de naissance du responsable' : 'Lieu de naissance' }} :
                  </span>
                  <span class="title-result-show">{{ owner?.lieuN }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? 'Nationnalité du responsable' : 'Nationnalité' }} :
                  </span>
                  <span class="title-result-show">{{ owner?.nationalite }}</span>
                </p>
                <p *ngIf="owner?.type === 'ENTREPRISE'">
                  <span class="title-show">N° Compte contribualbe: </span>
                  <span class="title-result-show">{{ owner?.ncc }}</span>
                </p>
                <p *ngIf="owner?.type === 'ENTREPRISE'">
                  <span class="title-show">N° Registre de commerce: </span>
                  <span class="title-result-show">{{ owner?.nrc }}</span>
                </p>
                <p *ngIf="owner?.type === 'ENTREPRISE'">
                  <span class="title-show">Téléphone du responsable: </span>
                  <span class="title-result-show">{{ owner?.telResponsable }}</span>
                </p>
                <p *ngIf="owner?.type === 'ENTREPRISE'">
                  <span class="title-show">Poste du responsable: </span>
                  <span class="title-result-show">{{ owner?.postOccupe }}</span>
                </p>
                <p>
                  <span class="title-show">
                    {{ owner?.type === 'ENTREPRISE' ? 'Sexe du responsable' : 'Sexe' }} :
                  </span>
                  <span class="title-result-show">{{ owner?.sexe }}</span>
                </p>
                <p *ngIf="owner?.type === 'PARTICULIER'">
                  <span class="title-show">Situation matrimoniale: </span>
                  <span class="title-result-show">{{ owner?.situationMatrimoniale }}</span>
                </p>
                <p *ngIf="owner?.type === 'PARTICULIER'">
                  <span class="title-show">Nombre d'enfant: </span>
                  <span class="title-result-show">{{ owner?.enfant }}</span>
                </p>
                <p *ngIf="owner?.type === 'PARTICULIER'">
                  <span class="title-show">Personne en cas d'urgence : </span>
                  <span class="title-result-show">{{ owner?.nomUrgence }}</span>
                </p>
                <p *ngIf="owner?.type === 'PARTICULIER'">
                  <span class="title-show">Numéro d'urgence : </span>
                  <span class="title-result-show">{{ owner?.contactUrgence }}</span>
                </p>
              </div>
              <!-- COL DROITE -->
              <div class="flex-fill">
                <p *ngIf="owner?.type === 'PARTICULIER'">
                  <span class="title-show">Affinité partagée : </span>
                  <span class="title-result-show">{{ owner?.affiniteUrgence}}</span>
                </p>
                <p>
                  <span class="title-show">Nature de la pièce: </span>
                  <span class="title-result-show">{{ owner?.naturePiece }}</span>
                </p>
                <p>
                  <span class="title-show">Numéro de la pièce: </span>
                  <span class="title-result-show">{{ owner?.numPiece }}</span>
                </p>
                <p>
                  <span class="title-show">Validité de la pièce : </span>
                  <span class="title-result-show">{{ owner?.dateExpirePiece | date: "d MMMM y" : '' : 'fr-FR' }}</span>
                </p>
                <p>
                  <span class="title-show">Date d'émission: </span>
                  <span class="title-result-show">{{ owner?.dateEmission | date: "d MMMM y" : '' : 'fr-FR' }}</span>
                </p>
                <p>
                  <span class="title-show">Signature de l'autorité: </span>
                  <span class="title-result-show">{{ owner?.signatureAutorite }}</span>
                </p>
                <p>
                  <span class="title-show">Crée le : </span>
                  <span class="title-result-show">{{ owner?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' :
                    'fr-FR'}}</span>
                  <span class="title-show ml-5"> Par : </span>
                  <span class="title-result-show">{{ owner?.create }}</span>
                </p>
                <p>
                  <span class="title-show">Modifié le : </span>
                  <span class="title-result-show">{{ owner?.updatedAt| date: "d MMMM y 'à' h:mm:ss" : '' :
                    'fr-FR'}}</span>
                  <span class="title-show ml-5"> Par : </span>
                  <span class="title-result-show">{{ owner?.update }}</span>
                </p>
              </div>
            </div>
            <div class="col-md" *ngIf="owner">
              <span class="badge badge-primary mb-4 f-14 width">Fiche de dossier</span>
              <div class="row">
                <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
                  <app-folder-uploader (click)="showFile(owner?.folder)" [maxSize]="3" [folder]="owner?.folder" [etat]="'show'"
                    [allowFileUpload]="false" [allowDownload]="true">
                  </app-folder-uploader>
                </div>
                <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
                  <div class="row">
                    <div class="col-sm-12 mb-2">
                      <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                        <i class="fa fa-arrow-alt-circle-left"></i> Fermer
                      </button>
                    </div>
                  </div>
                  <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
                  </ngx-doc-viewer>

                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(owner)" type="button"
                class="btn btn-primary" ngbTooltip="Modifier">
                Modifier <i class="feather icon-edit-2"></i>
              </button>
              <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(owner)" type="submit"
                class="btn btn-warning" ngbTooltip="Imprimer">
                Imprimer <i class="feather icon-printer"></i>
              </button>
              <ng-template ngxPermissionsOnly="OWNER:DELETE">
                <button *ngIf="owner?.isDelete" (click)="delete(owner)" type="button"
                  class="btn btn-danger text-left" data-dismiss="modal" ngbTooltip="Supprimer">
                  Supprimer <i class="feather icon-trash"></i>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'BIEN'}" id="bien" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <!-- Listes des biens -->
          <div class="col-md-12" *ngIf="type === 'BIEN' && houses && houses.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
              Listes des biens
            </span>
            <app-no-data [title]="'Aucun bien trouvé'" *ngIf="type === 'BIEN' && houses && houses.length === 0">
            </app-no-data>
          </div>
          <app-house-list [houses]="houses" [proprietaire]="false" class="width"></app-house-list>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'LOCATIVE'}" id="locative" role="tabpanel"
        aria-labelledby="locative-tab">
        <div class="row">
          <!-- LISTE DES LOCATIVES -->
          <div class="col-md-12" *ngIf="type === 'BIEN' && houses && houses.length === 0">
            <span class="badge badge-info mb-4 f-14 width">
              Liste des locatives
            </span>
            <app-no-data [title]="'Aucune locative trouvée'" *ngIf="rentals && rentals.length === 0"></app-no-data>
          </div>
          <div class="col-md-12" *ngIf="rentals">
            <span class="badge badge-info mb-4 f-14 width">
              Liste des locatives
            </span>
            <app-card *ngIf="rentals && rentals.length > 0" [hidHeader]="true" cardClass="card-datatable">
              <div class="row">
                <app-rental-list [rentals]="rentals" [action]="true" class="width"></app-rental-list>
              </div>
            </app-card>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'MANDAT'}" id="mandat" role="tabpanel"
        aria-labelledby="mandat-tab">
        <div class="row">
          <!-- LISTE DES MANDATS -->
          <div class="col-md-12" *ngIf="mandates && mandates.length === 0">
            <span class="badge badge-warning mb-4 f-14 width">
              Liste des mandats
            </span>
            <app-no-data [title]="'Aucun mandat trouvé'" *ngIf="mandates.length == 0"></app-no-data>
          </div>
          <div class="col-md-12">
            <div class="row" *ngIf="mandates && mandates.length > 0">
              <app-mandate-list [mandates]="mandates" [action]="true" class="width"></app-mandate-list>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'REVERSEMENT'}" id="reversement"
        role="tabpanel" aria-labelledby="reversement-tab">
        <div class="row">
          <!-- LISTE DES REVERSEMENTS -->
          <div class="col-md-12" *ngIf="repayments">
            <span class="badge badge-success mb-4 f-14 width">
              Liste des reversements
            </span>
            <app-no-data [title]="'Aucun réversement trouvé'" *ngIf="repayments && repayments.length === 0">
            </app-no-data>
          </div>
          <app-repayment-list *ngIf="repayments && repayments.length > 0" [repayments]="repayments" class="width">
          </app-repayment-list>
        </div>
      </div>

      <!-- LISTE DES NOTES_INTERNES -->
      <div class="width list-notes-internes" *ngIf="activeTab === 'NOTE_INTERNE'">
        <app-activity-list [notes]="notes" [rdvs]="rdvs"></app-activity-list>
      </div>

    </div>
  </div>
</div>
