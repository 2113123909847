<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-warning my-2 f-14 width"> DÉTAILS DU MOT DE PASSE</span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <label for="user">Utilisateur <span class="asterix">*</span></label>
            <!-- <select formControlName="user" class="form-control" id="user"
            [ngClass]="{
            'is-invalid': submit && f.user.errors,
            'is-valid': submit && f.user.valid }">
            <option *ngFor="let item of users" [ngValue]="item.uuid">{{item.libelle}}</option>
            </select> -->
            <app-entity-finder [class]="'User'" [groups]="['user']" [required]="true"
              (uuid)="setUserUuid($event)" [namespace]="'Admin'" [placeholder]="'Sélectionnez un utilisateur'">
            </app-entity-finder>
            <div class="invalid-feedback" *ngIf="submit && f.user.errors">
              <div *ngIf="f.user.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-5">
            <label for="user">Mot de passe <span class="asterix">*</span></label>
            <div class="input-group mb-3">
              <input type="text" formControlName="new" class="form-control" id="new"
                [ngClass]="{
                  'is-invalid': submit && f.new.errors,
                  'is-valid': submit && f.new.valid
                }" placeholder="Mot de passe">
              <div class="invalid-feedback" *ngIf="submit && f.new.errors">
                <div *ngIf="f.new.errors.required">{{required.novide}}</div>
              </div>
              <div class="input-group-append" (click)="onTogglePassword()">
                <span class="input-group-text"><span class="fas fa-copy"></span></span>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button (click)="setPassword()" type="button" class="btn btn-warning m-1" ngbTooltip="Genére mot de passe">
              Genéré <i class="fas fa-redo"></i>
            </button>
          </div>
      </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
