<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="modale.close('ferme')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="d-flex">
        <!-- COL GAUCHE -->
        <div class="flex-fill">
          <p *ngIf="invoice?.trustee">
            <span class="title-show">Syndic : </span>
            <span class="title-result-show">
              <span class="badg badge-light-warninge" >{{ invoice?.trustee?.nom}}
              </span>
            </span>
          </p>
          <p>
            <span class="title-show">Référence : </span>
            <span class="title-result-show">{{ invoice?.code }}</span>
          </p>
          <p>
            <span class="title-show">Numéro : </span>
            <span class="title-result-show">{{ invoice?.numero }}</span>
          </p>
          <p>
            <span class="title-show">Libellé : </span>
            <span class="title-result-show">{{ invoice.libelle }}</span>
          </p>
          <p>
            <span class="title-show">Intervention : </span>
            <span class="title-result-show">{{ invoice?.construction?.nom }}</span>
          </p>
          <p>
            <span class="title-show">{{invoice?.type === 'PRESTATION'?'Prestataire':'Fournisseur'}} : </span>
            <span class="title-result-show">{{ invoice?.provider?.nom}}</span>
          </p>
          <p>
            <span class="title-show">Type : </span>
            <span class="title-result-show">
              <span class="badge" [ngClass]="{
                'badge-light-warning' : invoice?.type === 'PRESTATION',
                'badge-light-primary' : invoice?.type === 'FOURNITURE'
                }">{{ invoice?.type}}
              </span>
            </span>
          </p>
          <p *ngIf="invoice?.type === 'PRESTATION'">
            <span class="title-show">Montant prestation : </span>
            <span class="title-result-show"> {{ invoice?.prestation |number }} {{ global.device }}</span>
          </p>
          <p>
            <span class="title-show">Montant Payé: </span>
            <span class="title-result-show">
              <span class="badge badge-success">{{invoice?.montantPaye | number}} {{global.device}}
              </span>
            </span>
          </p>
          <p>
            <span class="title-show">Resta à Payé: </span>
            <span class="title-result-show">
              <span class="badge badge-warning">{{(invoice?.montant - invoice?.montantPaye) | number}} {{global.device}} 
              </span>
            </span>
          </p>
        </div>
        <!-- COL DROITE -->
        <div class="flex-fill">
          <p *ngIf="invoice?.house || invoice?.home">
            <span class="title-show">Lot : </span>
            <span class="title-result-show">{{ invoice?.house?.nom }} {{ invoice?.home?.nom }}</span>
          </p>
          <p *ngIf="invoice?.infrastructure">
            <span class="title-show">Infrastructure : </span>
            <span class="title-result-show">{{ invoice?.infrastructure?.nom }}</span>
          </p>
          <p>
            <span class="title-show">Echéance : </span>
            <span class="title-result-show">{{ invoice.echeance|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
          </p>
          <p>
            <span class="title-show">Date : </span>
            <span class="title-result-show">{{ invoice?.date|date: "d MMMM y" : '' : 'fr-FR'}}</span>
          </p>
          <p>
            <span class="title-show">ÉTAT: </span>
            <span class="title-result-show">
              <span class="badge" [ngClass]="{
                'badge-danger' : invoice?.etat === 'INVALIDE',
                'badge-success' : invoice?.etat === 'VALIDE'
                }">{{ validation(invoice?.etat)}}
              </span>
            </span>
          </p>
          <p>
            <span class="title-show">Crée le : </span>
            <span class="title-result-show">{{ invoice?.createdAt | date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
            <span class="title-show ml-5"> Par : </span>
            <span class="title-result-show">{{ invoice?.create }}</span>
          </p>
          <p>
            <span class="title-show">Modifié le : </span>
            <span class="title-result-show">{{ invoice?.updatedAt | date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
            <span class="title-show ml-5"> Par : </span>
            <span class="title-result-show">{{ invoice?.update }}</span>
          </p>
        </div>
      </div>
      <div *ngIf="invoice?.payments?.length>0">
        <span class="badge badge-primary mb-4 f-14 width">PAIEMENTS</span>
      <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
            <tr>
              <th>Montant</th>
              <th>Mode de paiement</th>
              <th>État</th>
              <th>Date de paiement</th>
              <th>Crée le</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of invoice?.payments">
              <td><span class="text-warning">{{item?.montant|number}} {{global.device}}</span></td>
              <td><span class="text-warning">{{item?.mode}}</span></td>
              <td>
                <span class="badge" [ngClass]="{
                'badge-success' : item?.etat === 'VALIDE',
                'badge-danger' : item?.etat === 'INVALIDE'
                }">{{validation(item?.etat)}}</span> <br />
                <span class="badge " [ngClass]="{
                'badge-light-success' : item?.status === 'CONFIRMER',
                'badge-light-danger' : item?.status === 'ATTENTE'
                }"><small>{{confirmation(item?.status)}}</small></span>
              </td>
              <td>{{item?.date|date: "d MMMM y h:mm:ss" : '' : 'fr-FR'}}</td>
              <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      <span class="badge badge-primary mb-4 f-14 width">OPTIONS</span>
      <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
            <tr>
              <th>Désigantion</th>
              <th>Prix u.</th>
              <th>Qte</th>
              <th>Tva</th>
              <th>Remise</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of invoice?.options">
              <td>
                <p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p>
              </td>
              <td>
                <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix|number}}
                    {{global.device}}</span></p>
              </td>
              <td>{{item?.qte}}</td>
              <td>{{item?.tva}} %</td>
              <td>
                <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise|number}}
                    {{global.device}}</span>
              </td>
              <td>
                <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(item?.total)|number}}
                    {{global.device}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="row">
        <div class="col-sm-12">
          <table class="table table-responsive  invoice-table invoice-total">
            <tbody>
              <tr>
                <th>TOTAL HT : </th>
                <td> {{ invoice?.montantHt | number }} {{global.device}}</td>
              </tr>
              <tr>
                <th>TOTAL REMISE : </th>
                <td> {{ invoice?.montantRemise | number }} {{global.device}}</td>
              </tr>
              <tr>
                <th>TOTAL TVA : </th>
                <td> {{ invoice?.montantTva | number }} {{global.device}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md" *ngIf="invoice">
        <span class="badge badge-primary mb-4 f-14 width">Pièces jointes</span>
        <div class="row">
          <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
            <app-folder-uploader (click)="showFile(invoice.folder)" [folder]="invoice.folder"
              [allowFileUpload]="false" [allowDownload]="true">
            </app-folder-uploader>
          </div>
          <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
            <div class="row">
              <div class="col-sm-12 mb-2">
                <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                  <i class="fa fa-arrow-alt-circle-left"></i> Fermer
                </button>
              </div>
            </div>
            <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
            </ngx-doc-viewer>
          </div>
        </div>
      </div>
      <table class="width">
        <tbody>
          <tr class="border-bottom-success">
            <td></td>
            <td colspan="5" class="text-right font-weight-bold"></td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="border border-success">
            <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
            <td class="font-weight-bold font-size-default text-right">{{ invoice?.montant | number }} {{global.device}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <ng-template *ngxPermissionsOnly="'QUOTE:EDIT'">
      <button *ngIf="invoice?.etat === 'INVALIDE'" (click)="printQuote(invoice)" class="btn btn-primary">
        Modifier <i class="feather icon-edit-2"></i>
      </button>
    </ng-template>
    <button *ngxPermissionsOnly="'QUOTE:PRINTER:SHOW'" (click)="printerInvoice(invoice)" class="btn btn-warning">
      Imprimer <i class="feather icon-printer"></i>
    </button>
  </div>