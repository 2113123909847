<div class="row justify-content-center">
  <div class="col-md-12">
    <app-card [hidHeader]="true" blockClass="">
      <form [formGroup]="form" (ngSubmit)="onFilter()">
        <div class="form-group"></div>
        <div class="row">
          <div class="mb-2 col-md">
            <label for="owner">Propriétaire </label>
            <app-entity-finder [class]="'Owner'" [namespace]="'Client'" [groups]="'owner'" [required]="true"
              (uuid)="setValue($event, 'owner')" [selected]="ownerSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
          <div class="mb-2 col-md">
            <label for="tenant">Locataire </label>
            <app-entity-finder [class]="'Tenant'" [namespace]="'Client'" [groups]="'tenant'" [required]="true"
              (uuid)="setValue($event, 'tenant')" [selected]="tenantSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
          <div class="mb-2 col-md">
            <label for="customer">Client </label>
            <app-entity-finder [class]="'Customer'" [namespace]="'Client'" [groups]="'customer'" [required]="true"
              (uuid)="setValue($event, 'customer')" [selected]="customerSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
          <div class="mb-2 col-md">
            <label for="user">Utilisateur </label>
            <app-entity-finder [class]="'User'" [namespace]="'Admin'" [groups]="'user'" [required]="true"
              (uuid)="setValue($event, 'user')" [selected]="userSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="mb-2 col-md">
            <label for="house">Bien </label>
            <app-entity-finder [class]="'House'" [namespace]="'Client'" [groups]="'house'" [required]="true"
              (uuid)="setValue($event, 'house')" [selected]="houseSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
          <div class="mb-2 col-md">
            <label for="promotion">Programme Immobilier </label>
            <app-entity-finder [class]="'Promotion'" [namespace]="'Client'" [groups]="'promotion'" [required]="true"
              (uuid)="setValue($event, 'promotion')" [selected]="promotionSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
          <div class="mb-2 col-md">
            <label for="subdivision">Projet de Lotissement </label>
            <app-entity-finder [class]="'Customer'" [namespace]="'Client'" [groups]="'customer'" [required]="true"
              (uuid)="setValue($event, 'customer')" [selected]="customerSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
          <div class="mb-2 col-md">
            <label for="tresorerie">Trésorerie </label>
            <app-entity-finder [class]="'Treasury'" [namespace]="'Client'" [groups]="'tresorerie'" [required]="true"
              (uuid)="setValue($event, 'tresorerie')" [selected]="tresorerieSelected" [idOrUuid]="'ID'">
            </app-entity-finder>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="mb-2 col-md">
            <label for="dateD">Date de début</label>
            <input type="date" formControlName="dateD" id="dateD" class="form-control" placeholder="yyyy-mm-dd">
          </div>
          <div class="mb-2 ml-2 col-md">
            <label for="dateF">Date de fin</label>
            <input type="date" formControlName="dateF" id="dateF" class="form-control" placeholder="yyyy-mm-dd">
          </div>
          <div class="col-md mb-2">
            <label for="min">Montant Min</label>
            <input type="number" min="0" formControlName="min" id="min" class="form-control"
              [placeholder]="'Montant Min'">
          </div>
          <div class="col-md mb-2">
            <label for="max">Montant Max</label>
            <input type="number" min="0" formControlName="max" id="max" class="form-control"
              [placeholder]="'Montant Max'">
          </div>
          <div class="col-md mb-2">
            <label for="ordre">Ordre</label>
            <select formControlName="ordre" class="form-control" id="ordre">
              <option [value]="'ASC'">Croissant</option>
              <option [value]="'DESC'">Décroissant</option>
            </select>
          </div>
          <div class="col-md mb-2">
            <label for="count">Nombre</label>
            <select formControlName="count" class="form-control" id="count">
              <option *ngFor="let item of countRow" [value]="item.value">{{ item.label }}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="d-flex flex-row-reverse">
          <button type="submit" class="btn btn-primary">
            Filtrer <i class="feather icon-filter"></i>
          </button>
          <button type="submit" (click)="onPrinter()" class="btn btn-warning mr-3">
            Imprimer <i class="feather icon-printer"></i>
          </button>
        </div>
      </form>
    </app-card>
  </div>


  <div class="col-md-12">
    <span class="badge badge-primary my-2 f-14 formBadge width"> Liste des transactions financières</span>
  <div>
  <div class="col-md-12">
    <app-card [hidHeader]="true" cardClass="card-datatable">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
            <tr>
              <th>#ID</th>
              <th>Libellé</th>
              <th>Date d'opération</th>
              <th>Débit</th>
              <th>Crédit</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of locations">
              <td >
                <p class="m-0 d-inline-block align-middle font-16"> {{ item?.id }}</p>
              </td>
              <td >
                <p class="m-0 d-inline-block align-middle font-16"> {{ item?.title }}</p>
              </td>
              <td>{{item?.date|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
              <td class="align-middle">
                <span class="text-primary font-weight-blod">{{item?.debit|number}} {{global.device}}</span>
              </td>
              <td class="align-middle">
                <span class="text-success font-weight-blod">{{item?.credit|number}} {{global.device}}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
                <td [attr.colspan]="'3'" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                <td class="font-weight-bold font-size-40 text-primary"> {{0 | number}} {{global.device}}</td>
                <td class="font-weight-bold font-size-40 text-success"> {{sum?.loc | number}} {{global.device}}</td>
              </tr>
            </tfoot> 
        </table>
      </div>
    </app-card>
  </div>
</div>