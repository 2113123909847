import { Chat } from '@model/chat';
import { Ticket } from '@model/ticket';
import { Lightbox } from 'ngx-lightbox';
import { Router } from '@angular/router';
import { Category } from '@model/category';
import { environment } from '@env/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { ChatService } from '@service/chat/chat.service';
import { UserService } from '@service/user/user.service';
import { AuthService } from '@service/auth/auth.service';
import { TicketService } from '@service/ticket/ticket.service';
import { FilterService } from '@service/filter/filter.service';
import { EmitterService } from '@service/emitter/emitter.service';
import { CategoryService } from '@service/category/category.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TicketAddComponent } from '@reclamation/ticket/ticket-add/ticket-add.component';
import { FolderUploaderComponent } from '@theme/shared/folder-uploader/folder-uploader.component';
import { CategoryAddComponent } from '@agence/reclamation/category/category-add/category-add.component';
import { AssignationTicketComponent } from '@agence/modal/assignation-ticket/assignation-ticket.component';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  @ViewChild("folderUploader") folderUpload: FolderUploaderComponent;
  @ViewChild("scrollMe") scrollMe: ElementRef;

  filter: any
  type: string = 'TICKET'
  ticket: Ticket
  edit: boolean = false
  submit: boolean = false
  loading = false
  form: FormGroup
  tickets: Ticket[]
  stats: any
  categories: Category[]
  user: any
  users = []
  publicUrl = environment.publicUrl
  etatRow = [
    { label: 'OUVERT', value: 'OUVERT' },
    { label: 'FERME', value: 'FERME' }
  ]
  typeRow = [
    { label: 'TICKET', value: 'TICKET' },
    { label: 'CATEGORIE', value: 'CATEGORY' }
  ]

  public size = 'large-view';
  public showView = false;
  public basicContent: string;

  nameTitle: string = "Catégories"
  userTitle: string = "Crée par"

  constructor(
    public router: Router,
    private auth: AuthService,
    private lightbox: Lightbox,
    private modalService: NgbModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private userService: UserService,
    private chatService: ChatService,
    private ticketService: TicketService,
    private filterService: FilterService,
    private categoryService: CategoryService,
    private permissionsService: NgxPermissionsService
  ) {
    this.loadTickets();
    this.loadStats();
    this.loadUsers();
    this.user = this.auth.getDataToken() ? this.auth.getDataToken() : null;
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
    this.newForm()
    this.basicContent = '<p>Hello...</p>';
    this.emitter.event.subscribe((data) => {
      if (data.action === 'TICKET_ADD') {
        this.appendToList(data.payload);
        this.loadUsers();
      }
      if (data.action === 'CHAT_ADD') {
        this.ticket.chats.push(data.payload);
      }
    });
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      message: [null, [Validators.required]],
      ticket: [null],
      folders: this.formBuild.array([]),
      folderUuid: [null],
    });
  }
  appendToList(row) {
    this.tickets.unshift(row);
  }
  appendToChat(chat: Chat) {
    const ticketIndex = this.tickets.findIndex((obj => obj.uuid == chat.ticket.uuid));
    if (ticketIndex != -1) {
      this.tickets[ticketIndex].chats.push(chat)
      if (this.ticket.uuid == chat.ticket.uuid) {
        this.ticket.chats.push(chat)
      }
    }
  }
  onFilter($event) {
    this.filterService.type = this.type;
    this.categories = []
    this.filterService.search($event, 'ticket', null).subscribe(
      res => {
        this.filter = this.filterService.filter
        if (this.type === 'TICKET') {
          this.tickets = res;
          return this.tickets;
        }else if (this.type === 'CATEGORY') {
          this.categories = res;
          return this.categories;

        }
      }, err => { })
  }
  onChangeLoad($event) {
    this.type = $event
    if($event === 'TICKET'){
      this.nameTitle = 'Catégories'
      this.etatRow = [
        { label: 'OUVERT', value: 'OUVERT' },
        { label: 'FERME', value: 'FERME' }
      ]
      this.ticketService.getList().subscribe(res => { return this.tickets = res; }, error => {} );
    }else if($event === 'CATEGORY'){
      this.nameTitle = 'Libellé'
      this.etatRow = []
      this.categoryService.getList().subscribe(res => { return this.categories = res; }, error => {} );
    }
  }
  loadTickets() {
    this.ticketService.getList().subscribe(res => {
      this.tickets = res;
      this.ticket = this.tickets[0];
      console.log(res);
      return this.tickets;
    }, error => { });
  }
  loadStats() {
    this.ticketService.getStats().subscribe(res => {
      this.stats = res;
      return this.stats;
    }, error => { });
  }
  loadUsers() {
    this.userService.getList(null, null, true).subscribe(res => {
      this.users = res;
      return this.users;
    }, error => { });
  }
  addTicket(type) {
    this.modalService.dismissAll()
    this.ticketService.edit = false
    this.ticketService.type = type
    this.modal(TicketAddComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  addCategory(type) {
    this.modalService.dismissAll()
    this.categoryService.edit = false
    this.categoryService.type = type
    this.modal(CategoryAddComponent, 'modal-basic-title', 'md', true, 'static')
  }
  showTicket(ticket) {
    this.ticket = ticket;
    this.showView = !this.showView;
  }
  assignTicket(ticket) {
    this.ticketService.ticket = ticket;
    this.modal(AssignationTicketComponent, 'modal-basic-title', 'md', true, 'static')
  }
  countOpenedTickets(tickets){
    const result = tickets.filter(ticket => ticket.etat === "OUVERT");
    return result.length;
  }
  countClosedTickets(tickets){
    const result = tickets.filter(ticket => ticket.etat === "FERME");
    return result.length;
  }
  onSubmit() {
    this.f.ticket.setValue(this.ticket.uuid)
    this.emitter.disallowLoading()
    if (!this.form.invalid) {
      this.loading = true;
      this.chatService.add(this.form.value).subscribe(res => {
        this.emitter.emit({ action: 'CHAT_ADD', payload: res?.data });
        this.loading = false;
        this.clearForm();
      }, error => {
        this.loading = false;
      });
    } else {
      return
    }
  }
  close(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment fermer cet ticket ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Fermer <i class="feather icon-x"></i>',
      confirmButtonColor: '#d33',
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.ticketService.close(item.uuid).subscribe(res => {
          this.showView = !this.showView;
          this.loadTickets()
        }, error => { });
      }
    });
  }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet ticket ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.ticketService.getDelete(item.uuid).subscribe(res => {
          this.loadTickets()
        }, error => { });
      }
    });
  }
  files(data) {
    if (data && data !== null) {
      data.forEach(item => {
        this.folder.push(
          this.formBuild.group({
            uuid: [item?.uuid, [Validators.required]],
            name: [item?.name],
            path: [item?.path]
          })
        );
      });
    }
  }
  setParam(property, value) {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, { [property]: value });
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }
  open(src) {
    const albums = [];
    const album = {
      src: src,
      caption: src,
      thumb: src
    };

    albums.push(album);
    this.lightbox.open(albums, 0);
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  clearForm() {
    this.form.reset();
    this.folderUpload.clearFiles();
  }
  getLastChat(item) {
    return item.chats[item.chats.length - 1];
  }
  getPastTime(data){
    return DateHelperService.fromNow(data);
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
    } catch (err) { }
  }

  get f() { return this.form.controls; }
  get folder() { return this.form.get('folders') as FormArray; }
}


