<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="modale.close('ferme')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <!-- COL GAUCHE -->
      <div class="flex-fill">        
          <!-- <p>
            <span class="title-show bold ">Code budgétaire : </span>
            <span class="title-result-show">{{ typeLoad?.code}} </span>
          </p> -->
          <p>
            <span class="title-show bold ">Libellé : </span>
            <span class="title-result-show text-warning">{{typeLoad?.libelle}}</span>
          </p>
      </div>
      <!-- COL MILLIEU -->
      <div class="flex-fill">        
        <p>
          <span class="title-show bold">Crée le : </span>
          <span class="title-result-show">{{typeLoad?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
          <span class="title-show bold ml-5"> Par : </span>
          <span class="title-result-show">{{ typeLoad?.create }}</span>
        </p>
        <p>
          <span class="title-show bold">Modifié le : </span>
          <span class="title-result-show">{{ typeLoad?.updatedAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
          <span class="title-show bold ml-5"> Par : </span>
          <span class="title-result-show">{{typeLoad?.update }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      <i class="ion-md-close-circle"></i> Fermer
    </button>
    <button (click)="edit(typeLoad)" class="btn btn-primary">
      <i class="fas fa-pen"></i> Modifier
    </button>
    <button (click)="printer(typeLoad)" class="btn btn-warning">
      <i class="ion-md-print"></i> Imprimer
    </button>
  </div>
  