export const environment = {
  production: true,
  // publicUrl: "http://127.0.0.1:800O",
  // serverUrl: "http://127.0.0.1:800O/api",
  
  // serverUrl: "http://demo-api.simaubenin.com/api",
  // publicUrl: "http://demo-api.simaubenin.com",

  serverUrl: "http://zenapi-api.simaubenin.com/api",
  publicUrl: "http://zenapi-api.simaubenin.com",
  mercureUrl: "https://zenapi-mercure.herokuapp.com/.well-known/mercure"
};
