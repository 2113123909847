<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modale.close('ferme')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-md-12">
        <div class="d-flex">
            <!-- COL GAUCHE -->
            <div class="flex-fill">
                <p>
                    <span class="title-show">Reférence : </span>
                    <span class="title-result-show">{{ terminate?.code }}</span>
                </p>
                <p>
                    <span class="title-show"> État : </span>
                    <span class="title-result-show">
                        <span *ngIf="terminate?.etat" class="badge badge-success" [ngClass]="{
                            'badge-success' : terminate?.etat === 'VALIDE',
                            'badge-warning' : terminate?.etat === 'INVALIDE',
                            'badge-danger' : terminate?.etat === 'RESILIE'
                            }">{{validation(terminate?.etat)}}</span>
                    </span>
                </p>
                <p>
                    <span class="title-show"> Date : </span>
                    <span class="title-result-show">{{ terminate?.date|date: "d MMMM y" : '' : 'fr-FR'}}</span>
                </p>
                <p>
                    <span class="title-show"> Propriétaire : </span>
                    <span class="title-result-show">{{ terminate?.owner?.nom }}</span>
                </p>
                <p>
                    <span class="title-show">Bien concerné : </span>
                    <span class="title-result-show">{{ terminate?.mandate?.house?.nom }}</span>
                </p>
                <p>
                    <span class="title-show"> Type de bien : </span>
                    <span class="title-result-show">{{ terminate?.mandate?.house?.type }}</span>
                </p>
            </div>
            <!-- COL DROITE -->
            <div class="flex-fill">
                <p>
                    <span class="title-show"> N° Mandat : </span>
                    <span class="title-result-show">{{ terminate?.mandate?.code }}</span>
                </p>
                <p>
                    <span class="title-show"> Type Mandat : </span>
                    <span class="title-result-show">{{ terminate?.mandate?.type}}</span>
                </p>
                <p>
                    <span class="title-show"> libelle : </span>
                    <span class="title-result-show">{{ terminate?.mandate?.libelle }}</span>
                </p>
                <p>
                    <span class="title-show">Crée le : </span>
                    <span class="title-result-show">{{ terminate?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'
                        }}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ terminate?.create }}</span>
                </p>
                <p>
                    <span class="title-show">Modifié le : </span>
                    <span class="title-result-show">{{ terminate?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'
                        }}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ terminate?.update }}</span>
                </p>
                <p>
                    <span class="title-show">Validé le : </span>
                    <span class="title-result-show">{{ terminate?.validateAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'
                        }}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ terminate?.validate }}</span>
                </p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
        Fermer <i class="feather icon-x-circle"></i>
    </button>
    <!-- <ng-template *ngxPermissionsOnly="'TERMINATE:MANDAT:EDIT'"> -->
    <!-- <button *ngIf="mandate?.etat === 'INVALIDE'" (click)="editMandale(terminate)" class="btn btn-primary"> -->
    <button (click)="editTerminateMandate(terminate)" class="btn btn-primary">
        Modifier <i class="feather icon-edit-2"></i>
    </button>
    <!-- </ng-template> -->
    <!-- <button *ngxPermissionsOnly="'MANDAT:PRINTER:SHOW'" (click)="printerMandate(terminate)" class="btn btn-warning"> -->
    <button (click)="printerMandate(terminate)" class="btn btn-warning">
        Imprimer <i class="feather icon-printer"></i>
    </button>
</div>