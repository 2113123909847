import { Component, OnInit } from '@angular/core';
import { Treasury } from '@model/treasury';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreasuryService } from '@service/treasury/treasury.service';
import { Globals } from '@theme/utils/globals';
import { UserService } from '@service/user/user.service';
import { User } from '@model/user';
import { DualListComponent } from 'angular-dual-listbox';
import { EmitterService } from '@service/emitter/emitter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SyndicService } from '@service/syndic/syndic.service';
import { PlanComptableService } from '@service/configuration/plan-comptable.service';
import { JournauxService } from '@service/configuration/journaux.service';


@Component({
  selector: 'app-treasury-add',
  templateUrl: './treasury-add.component.html',
  styleUrls: ['./treasury-add.component.scss']
})
export class TreasuryAddComponent implements OnInit {
  tab = 1;
  keepSorted = true;
  key: string;
  display: string;
  filter = false;
  source: Array<any>;
  confirmed: Array<any>;
  userAdd = '';
  disabled = false;
  sourceLeft = true;
	private DEFAULT_FORMAT = {
    add: 'Ajouter',
    remove: 'Supprimer',
    all: 'Tout sélectionner',
    none: 'Annuler',
    direction:
    DualListComponent.LTR,
    draggable: true
  };
  format: any = this.DEFAULT_FORMAT;
  private sourceStations: Array<any>;
  private confirmedStations: Array<any>;
  private stations: Array<any> = [];

  title: string = ""
  edit: boolean = false
  treasury: Treasury
  users: User[]
  validateurRow: User[]
  form: FormGroup
  submit: boolean = false
  required = Globals.required

  typeRow = [
    { label: "CAISSE", value: "CAISSE" },
    { label: "BANQUE", value: "BANQUE" },
  ]

  concerns = [
    { label: "AGENCE", value: "AGENCY" },
    { label: "SYNDIC", value: "TRUSTEE" },
  ]

  trustees: any[] = []
  agency = Globals.user.agencyKey
  accounts: any[] = []
  logs: any[] = []
  selectedLog: any

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    public treasuryService: TreasuryService,
    private emitter: EmitterService,
    private userService: UserService,
    private trusteeService: SyndicService,
    private accountService: PlanComptableService,
    private logService: JournauxService
  ) {
    this.edit = this.treasuryService.edit
    this.treasury = this.treasuryService.getTreasury()
    this.title = (!this.edit) ? "Ajouter une trésorerie" : "Modifier la trésorerie "+this.treasury.nom
    this.newForm()
  }

  ngOnInit(): void {
    this.userService.getList(null, null).subscribe(res => {
      if(res){
        this.users = res ? res : []
        res?.forEach(item => {
          this.stations.push({
            key: item?.id,
            station: item?.nom,
            state: item?.uuid
          })
        });
        this.editForm()
        this.doReset();
      }
    })
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      type: ['CAISSE', [Validators.required]],
      compte: [null],
      nom: [null, [Validators.required]],
      seuilMin: [0, [Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      seuilMax: [0, [Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      gerant: [null, [Validators.required]],
      validateurs: this.formBuild.array([]),
      commentaire: [null],
      concern: ['AGENCY', [Validators.required]],
      trustee: [null],
      account: [null],
      test: [null],
      log: [null],
      numero: [null],
    });
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.treasuryService.getTreasury() }
      console.log(data)
      this.form.patchValue(data)
      this.f.gerant.setValue(data?.gerant?.uuid)
      this.validateurRow = data.validateurs
      this.f.trustee.setValue(data?.trustee?.uuid)
      this.f.account.setValue(data?.account?.uuid)
      this.f.log.setValue(data?.log?.uuid)
      this.f.numero.setValue(data?.auxiliary?.numero)
    }
  }
  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      this.treasuryService.add(this.form.getRawValue()).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (this.form.value.uuid) {
            this.emitter.emit({action: 'TREASURY_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'TREASURY_ADD', payload: res?.data});
          }
        }
      }, error => {});
    } else {
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  setData(){
    this.validateurs.controls = [];
    this.confirmed.forEach(item =>{
      this.validateurs.controls.push(
        this.formBuild.group({
          uuid: [item?.state],
          libelle: [item?.station],
        })
      );
    })
  }
  private useStations() {
    this.key = 'key';
    this.display = 'station';
    this.keepSorted = true;
    this.source = this.sourceStations;
    this.confirmed = this.confirmedStations;
  }
  doReset() {
    this.sourceStations = JSON.parse(JSON.stringify(this.stations));
    this.confirmedStations = new Array<any>();
    if(this.edit){
      if(this.validateurRow.length > 0){
        this.validateurRow.forEach(item => {
          this.stations.forEach((key, i) => {
            if(item.id === key.key){ this.confirmedStations.push(this.stations[i]); }
          })
          this.validateurs.controls.push(
            this.formBuild.group({
              uuid: [item?.uuid],
              libelle: [item?.nom],
            })
          );
        })
      }
    }
    this.useStations();
  }
  filterBtn() { return (this.filter ? 'Hide Filter' : 'Show Filter'); }
  doDisable() { this.disabled = !this.disabled; }
  disableBtn() { return (this.disabled ? 'Enable' : 'Disabled'); }
  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }

  onChangeConcern(value) {
    if (value && value === 'TRUSTEE') {
      this.trusteeService.getList(this.agency).subscribe((res) => {
        console.log(res)
        if (res) {
          this.trustees = res
        }
      })

      this.accountService.getList(this.agency).subscribe((res) => {
        console.log(res)
        if (res) {
          this.accounts = []
          res.forEach((account) => {
            if (account.etat === 'ACTIF') {
              this.accounts.push(account)
            }
          })
        }
      })

      this.logService.getList(this.agency).subscribe((res) => {
        console.log(res)
        if (res) {
          this.logs = []
          res.forEach((log) => {
            if (log.etat === 'ACTIF') {
              this.logs.push(log)
            }
          })
        }
      })
    } else {
      this.f.trustee.setValue(null)
      this.trustees = []
    }
  }
  onNomChange() {
    let accountUuid = this.f.account.value
    let libelle = this.f.nom.value
    if (accountUuid && libelle) {
      this.generateNumero(accountUuid, libelle)
    }
  } 

  onAccountChange(uuid) {
    console.log(uuid)
    if(uuid){
      if (this.f.nom.value) {
        this.generateNumero(uuid, this.f.nom.value)
      }
    }
  }

  generateNumero(uuid, libelle) {
    if (uuid && libelle) {
      this.accountService.getSingle(uuid).subscribe((res) => {
        if (res) {
          let string = res.baseNumero.toString()
          let label_prefix = libelle.substring(0, 3).toUpperCase();
          let numero = string + label_prefix;
          this.f.numero.setValue(numero)
        }
      })
    }
  }

  get f() { return this.form.controls; }
  get validateurs() { return this.form.get('validateurs') as FormArray; }
}
