<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <br />
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions"
        class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Locative</th>
            <th *ngIf="locataire">Locataire</th>
            <th>Libellé</th>
            <th>État</th>
            <th>Crée le</th>
            <th>Montant</th>
            <th>Payé</th>
            <th>Impayé </th>
            <th>Trop Percu </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of autres">
            <td class="align-middle">
              <p *ngIf="item?.contract" class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.contract?.rental?.libelle}}</span> <br />
                Superficie : {{item?.contract?.rental?.superficie}} m² - {{item?.contract?.rental?.piece}} pièce(s):
                <br />
                Propriétaire : {{item?.contract?.rental?.house?.owner?.searchableTitle}}
              </p>
              <p *ngIf="item?.short" class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.short?.rental?.libelle}}</span> <br />
                Superficie : {{item?.short?.rental?.superficie}} m² - {{item?.short?.rental?.piece}} pièce(s):
                <br />
                Propriétaire : {{item?.short?.rental?.house?.owner?.searchableTitle}}
              </p>
            </td>
            <td *ngIf="locataire">
              <p *ngIf="item?.contract" class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.contract?.tenant?.searchableTitle}}</span> <br />
                Type : {{item?.contract?.tenant?.type}}<br />
                Téléphone : {{item?.contract?.tenant?.telephone}}
              </p>
              <p *ngIf="item?.short" class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.short?.tenant?.searchableTitle}}</span> <br />
                Type : {{item?.short?.tenant?.type}}<br />
                Téléphone : {{item?.short?.tenant?.telephone}}
              </p>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">{{ item?.libelle }}</p>
              <br *ngIf="item?.contract && item?.contract?.etat !== 'ACTIF'" />
              <span *ngIf="item?.contract && item?.contract?.etat !== 'ACTIF'" class="badge" [ngClass]="{
                'badge-light-danger' : item?.contract?.etat === 'RESILIE',
                'badge-light-warning' : item?.contract?.etat === 'INACTIF',
                'badge-light-primary' : item?.contract?.etat === 'ACTIF'
              }"><small>{{item?.statut}}</small></span>

              <!-- CONTRAT A VOURT TERME -->
              <br *ngIf="item?.short && item?.short?.etat !== 'ACTIF'" />
              <span *ngIf="item?.short && item?.short?.etat !== 'ACTIF'" class="badge" [ngClass]="{
                'badge-light-danger' : item?.short?.etat === 'RESILIE',
                'badge-light-warning' : item?.short?.etat === 'INACTIF',
                'badge-light-primary' : item?.short?.etat === 'ACTIF'
              }"><small>{{item?.statut}}</small></span>
            </td>
            <td>
              <span class="badge" [ngClass]="{
              'badge-danger' : item?.etat === 'IMPAYÉ',
              'badge-primary' : item?.etat === 'ATTENTE',
              'badge-warning' : item?.etat === 'EN COURS',
              'badge-success' : item?.etat === 'SOLDE'
              }">{{PAYMENT(item.etat)}}</span>
            </td>
            <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="align-middle">
              <span class="text-primary font-weight-blod">{{item?.montant|number}} {{global.device}}</span>
            </td>
            <td class="align-middle">
              <span class="text-success font-weight-blod">{{item?.paye|number}} {{global.device}}</span>
            </td>
            <td class="align-middle">
              <span class="text-danger font-weight-blod">{{item.impaye|number}} {{global.device}}</span>
            </td>
            <td class="align-middle">
              <span class="text-danger font-weight-blod">{{item.solde|number}} {{global.device}}</span>
            </td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showAutre(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <ng-template ngxPermissionsOnly="INVOICE:EDIT">
                  <button *ngIf="item?.etat === 'ATTENTE'" (click)="editAutre(item)" type="button"
                    class="btn btn-icon  btn-primary  ml-1" ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                </ng-template>
                <button *ngxPermissionsOnly="'INVOICE:PRINTER:SHOW'" (click)="printerInvoice(item)" type="button"
                  class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
                <ng-template ngxPermissionsOnly="INVOICE:DELETE">
                  <button *ngIf="item?.contract?.etat !== 'RESILIE'" type="button" (click)="delete(item)"
                    class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="locataire ? '5' : '4'" class="font-weight-bold font-size-40 text-right">TOTAL</td>
            <td class="font-weight-bold font-size-40 text-primary"> {{total | number}} {{global.device}}</td>
            <td class="font-weight-bold font-size-40 text-success"> {{paye | number}} {{global.device}}</td>
            <td class="font-weight-bold font-size-40 text-danger"> {{impaye | number}} {{global.device}}</td>
            <td *ngIf="action"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </app-card>
</div>
