<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" id="report-table" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th *ngIf="client">Client</th>
            <th>Dossier</th>
            <th>État</th>
            <th>Date</th>
            <th>Crée le</th>
            <th>Montant</th>
            <th>Montant agence</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of terminates">
            <td *ngIf="client">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-primary">{{item?.folder?.customer?.searchableTitle}}</span> <br />
                Type : {{item?.folder?.customer?.type}}<br />
                Téléphone : {{item?.folder?.customer?.telephone}}
              </p>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="badge" [ngClass]="{
                'badge-danger' : item?.folder?.etat === 'RESILIE',
                'badge-success' : item?.folder?.etat === 'ACTIF',
                'badge-warning' : item?.folder?.etat === 'INACTIF'
                }">{{item?.folder?.etat}}</span>
              </p>
            </td>
            <td>
              <span class="badge" [ngClass]="{
              'badge-success' : item?.etat === 'VALIDE',
              'badge-danger' : item?.etat === 'INVALIDE'
              }">{{validation(item?.etat)}}</span>
            </td>
            <td>{{item?.date|date: "d MMMM y" : '' : 'fr-FR'}}</td>
            <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td>
              <span class="text-warning">{{item?.montant|number}} {{global.device}}</span>
            </td>
            <td>
              <span class="text-warning">{{item?.montantAgence|number}} {{global.device}}</span>
            </td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showTerminate(item)" type="button" class="btn btn-icon btn-secondary"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <ng-template ngxPermissionsOnly="FOLDER:TERMINATE:EDIT">
                  <button *ngIf="item?.etat === 'INVALIDE'" (click)="editTerminate(item)" type="button" class="btn btn-icon btn-primary ml-1"
                    ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                </ng-template>
                <button *ngIf="item?.etat === 'INVALIDE' && validate" (click)="validateTerminate(item)" type="button"
                  class="btn btn-icon ml-1 btn-success" ngbTooltip="Valider">
                  <i class="fas fa-check"></i>
                </button>
                <ng-template ngxPermissionsOnly="FOLDER:TERMINATE:PRINTER:LIST">
                  <button *ngIf="item?.etat === 'VALIDE'" (click)="printerTerminate(item)" type="button" class="btn btn-icon btn-warning ml-1"
                    ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="FOLDER:TERMINATE:DELETE">
                  <button *ngIf="item?.etat === 'INVALIDE'" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="client ? '5' : '4'" class="font-weight-bold font-size-40 text-right">TOTAL</td>
            <td class="font-weight-bold font-size-40 text-warning"> {{total | number}} {{global.device}}</td>
            <td class="font-weight-bold font-size-40 text-warning"> {{totalA | number}} {{global.device}}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </app-card>
</div>
