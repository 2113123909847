<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
        <div class="row">
          <span class="badge badge-secondary my-2 f-14  width">
            Détails concernant le contrat
          </span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <app-entity-finder [class]="'Tenant'" [groups]="['tenant']" [required]="true"
              [label]="'Locataire'" (uuid)="setTenantUuid($event)"
              [selected]="tenantSelected"
              [disabled]="edit" [placeholder]="'Sélectionnez un locataire'">
            </app-entity-finder>
            <div class="invalid-feedback" *ngIf="submit && f.tenant.errors">
              <div *ngIf="f.tenant.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!edit">
            <div *ngIf="isLoadingContracts" class="spinner-container">
              <div class="spinner"></div>
            </div>

            <label for="contract">Contrat <span class="asterix">*</span></label>
            <select (change)="setContratUuid($event)" formControlName="contract" class="form-control"
              id="contract" [attr.disabled]="f.tenant.value ? null : 'true'"
              [ngClass]="{'is-invalid': submit && f.contract.errors,'is-valid': submit && f.contract.valid}">
              <option value="null" selected> Sélectionnez un contrat</option>
              <option *ngFor="let item of contracts" [value]="item.uuid">{{item.libelle}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.contract.errors">
              <div *ngIf="f.contract.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="edit">
            <label for="contract">Contrat <span class="asterix">*</span></label>
            <input type="text" class="form-control p-2 text-uppercase font-weight-bold text-secondary"
             [value]="libelle" readonly>
          </div>
        </div>
        <div *ngIf="f.contract.value">
          <div class="row">
            <span class="badge badge-secondary my-2 f-14  width"> EDITER LA FACTURE </span>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6">
              <label for="libelle">Libellé <span class="asterix">*</span></label>
              <input type="text" formControlName="libelle" class="form-control p-2"
                [ngClass]="{
                  'is-invalid': submit && f.libelle.errors,
                  'is-valid': submit && f.libelle.valid
                }" placeholder="Désignation">
            </div>
            <div class="col-sm-3">
              <label for="date">Date <span class="asterix">*</span></label>
              <input type="date" formControlName="date" class="form-control p-2"
                [ngClass]="{
                  'is-invalid': submit && f.date.errors,
                  'is-valid': submit && f.date.valid
                }">
            </div>
            <div class="col-sm-3">
              <label for="echeance">Echeance <span class="asterix">*</span></label>
              <input type="date" formControlName="echeance" class="form-control p-2"
                [ngClass]="{
                  'is-invalid': submit && f.echeance.errors,
                  'is-valid': submit && f.echeance.valid
                }">
            </div>
          </div>
          <div formArrayName="options">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label>Désignation <span class="asterix">*</span></label>
                </div>
                <div class="col-md-2">
                  <label>Prix <span class="asterix">*</span></label>
                </div>
                <div class="col-md-1">
                  <label>Qte <span class="asterix">*</span></label>
                </div>
                <div class="col-md-1">
                  <label>Tva</label>
                </div>
                <div class="col-md-2">
                  <label>Remise</label>
                </div>
                <div class="col-md-2">
                  <label>Total</label>
                </div>
              </div>
            </div>
            <div class="form-group" *ngFor="let item of option.controls; let i=index" [formGroupName]="i">
              <div class="row">
                <div class="col-md-3">
                  <input type="text" class="form-control p-2" formControlName="libelle" id="libelle{{i}}"
                   placeholder="Désignation" [ngClass]="{
                    'is-invalid': submit && item.get('libelle').errors,
                    'is-valid': submit && item.get('libelle').errors
                    }" placeholder="libelle">
                  <div class="invalid-feedback" *ngIf="submit && item.get('libelle').errors">
                    <div *ngIf="item.get('libelle').errors.required">{{required.novide}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input step="1000" type="number" (change)="onCalcul()" class="form-control p-2" formControlName="prix" id="prix{{i}}"
                   placeholder="Prix unitaire" [ngClass]="{
                    'is-invalid': submit && item.get('prix').errors,
                    'is-valid': submit && item.get('prix').errors
                    }">
                  <div class="invalid-feedback" *ngIf="submit && item.get('prix').errors">
                    <div *ngIf="item.get('prix').errors.required">{{required.novide}}</div>
                    <div *ngIf="item.get('prix').errors.pattern">{{required.nolettre}}</div>
                    <div *ngIf="opt.get('prix').errors.min">{{required.min}}</div>
                  </div>
                </div>
                <div class="col-md-1">
                  <input type="number" (change)="onCalcul()" class="form-control p-2" formControlName="qte" id="qte{{i}}"
                   placeholder="Quantité" [ngClass]="{
                    'is-invalid': submit && item.get('qte').errors,
                    'is-valid': submit && item.get('qte').errors
                    }">
                  <div class="invalid-feedback" *ngIf="submit && item.get('qte').errors">
                    <div *ngIf="item.get('qte').errors.required">{{required.novide}}</div>
                    <div *ngIf="item.get('qte').errors.pattern">{{required.nolettre}}</div>
                    <div *ngIf="opt.get('qte').errors.min">{{required.min}}</div>
                  </div>
                </div>
                <div class="col-md-1">
                  <input type="number" (change)="onCalcul()" class="form-control p-2" formControlName="tva" id="tva{{i}}"
                   placeholder="TVA"
                   [ngClass]="{
                    'is-invalid': submit && item.get('tva').errors,
                    'is-valid': submit && item.get('tva').errors
                    }">
                  <div class="invalid-feedback" *ngIf="submit && item.get('tva').errors">
                    <div *ngIf="item.get('tva').errors.pattern">{{required.nolettre}}</div>
                    <div *ngIf="opt.get('tva').errors.pattern">{{required.positive}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input step="1000" type="number" (change)="onCalcul()" class="form-control p-2" formControlName="remise" id="remise{{i}}"
                   placeholder="Remise"
                   [ngClass]="{
                    'is-invalid': submit && item.get('remise').errors,
                    'is-valid': submit && item.get('remise').errors
                    }">
                  <div class="invalid-feedback" *ngIf="submit && item.get('remise').errors">
                    <div *ngIf="item.get('remise').errors.pattern">{{required.nolettre}}</div>
                    <div *ngIf="opt.get('remise').errors.pattern">{{required.positive}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input type="number" class="form-control p-2 text-danger font-weight-bold" formControlName="total" id="total{{i}}"
                    placeholder="Total"
                    [ngClass]="{
                      'is-invalid': submit && item.get('total').errors,
                    'is-valid': submit && item.get('total').errors
                    }">
                  <div class="invalid-feedback" *ngIf="submit && item.get('total').errors">
                    <div *ngIf="item.get('total').errors.required">{{required.novide}}</div>
                    <div *ngIf="item.get('total').errors.pattern">{{required.nolettre}}</div>
                    <div *ngIf="opt.get('total').errors.pattern">{{required.positive}}</div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button (click)="onDelete(item)" type="button" class="btn btn-danger">
                    <i class="feather icon-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <button class="btn btn-msg-send btn-primary ml-auto"
            (click)="addOption()" type="button"> Ajouter <i class="fa fa-plus"></i></button>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table class="table table-responsive invoice-table invoice-total">
                <tbody>
                <tr>
                  <th>TOTAL HT :</th>
                  <td>{{ totalHt | number }}</td>
                </tr>
                <tr>
                  <th>TOTAL REMISE :</th>
                  <td>{{ totalRemise | number }}</td>
                </tr>
                <tr>
                  <th>TOTAL TVA :</th>
                  <td>{{ totalTva | number }}</td>
                </tr>
                <tr class="text-info">
                  <td>
                    <hr/>
                    <h2 class="text-primary m-r-10">TOTAL :</h2>
                  </td>
                  <td>
                    <hr/>
                    <h2 class="text-success">{{ totalTtc | number }}</h2>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
