<div class="card card-box rounded border-0 p-2">
    <div class="py-0">
        <h3 class="text-center">Plan des comptes auxiliaires</h3>
    </div>
    <hr class="border-2">
    <!-- <app-no-data *ngIf="auxiliaires && auxiliaires.length === 0" [title]="'Aucun compte auxiliaire trouvé'"></app-no-data>
    <div *ngIf="auxiliaires && auxiliaires.length > 0"> -->
        <span class="badge badge-primary my-2 f-14 width">Liste des comptes auxilliaires </span>
        <div class="table-responsive m-0">
            <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                <thead class="thead-light">
                <tr>
                    <th style="width: 20%;">Numéro du compte</th>
                    <th style="width: 50%;">Libellé</th>
                    <th style="width: 20%;">Libellé court</th>
                    <!-- <th style="width: 10%;">Type</th> -->
                    <th style="width: 10%;">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of auxiliaires">
                        <td>
                            <span>
                                {{item?.numero}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{item?.libelle}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{item?.libelleCourt}}
                            </span>
                        </td>
                        <!-- <td>
                            <span class="badge badge-dark">
                                {{item?.type}}
                            </span>
                        </td> -->
                        <td>
                            <button type="button" (click)="show(item)" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                <i class="fas fa-eye"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    <!-- </div> -->
</div>